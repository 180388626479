import store from '@/store'
import { $classes } from '@/main'
import { isOnline } from '@/functions/network'
import { createEvent } from '@/functions/events'
import { removeCalculatedParams } from '@/functions/paramsOffline'

const checkAppVersion = async (ver) => {
	let compare = versionCompare(ver, store.getters.appVersion)
	if (compare == 1) return true
	else return false
}

const versionCompare = (v1, v2, options) => {
	let lexicographical = options && options.lexicographical,
		zeroExtend = options && options.zeroExtend,
		v1parts = v1.split('.'),
		v2parts = v2.split('.')

	function isValidPart(x) {
		return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x)
	}
	if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
		return NaN
	}
	if (zeroExtend) {
		while (v1parts.length < v2parts.length) v1parts.push('0')
		while (v2parts.length < v1parts.length) v2parts.push('0')
	}
	if (!lexicographical) {
		v1parts = v1parts.map(Number)
		v2parts = v2parts.map(Number)
	}
	for (let i = 0; i < v1parts.length; ++i) {
		if (v2parts.length == i) {
			return 1
		}
		if (v1parts[i] == v2parts[i]) {
			continue
		} else if (v1parts[i] > v2parts[i]) {
			return 1
		} else {
			return -1
		}
	}
	if (v1parts.length != v2parts.length) {
		return -1
	}

	return 0
}

let isLoading = false
export const getVersion = async () => {
	if (isLoading) return
	isLoading = true
	try {
		await isOnline()
		await $classes.loadPublic()
		await store.dispatch('CurrentUser/requireAuthenticatedClasses')

		if (!$classes?.Person?.get_app_version) {
			console.debug(`[getVersion] no classes`)
			return
		}

		const ver = await $classes.Person.get_app_version()
		if (ver?.app_version) {
			const result = await checkAppVersion(ver.app_version)
			store.dispatch('UpdateApp/ShowSnackbarAction', result)
			store.dispatch('UpdateApp/assignServerAppVersion', ver.app_version)

			isLoading = false
			return result
		} else {
			isLoading = false
			await createEvent({
				status: 'Fail',
				details: 'Błąd przy pobieraniu nowej wersji aplikacji',
			})
			return false
		}
	} catch (e) {
		await createEvent({
			status: 'Fail',
			details: 'Błąd przy sprawdzaniu aktualnej wersji aplikacji',
		})
		console.error(e)
		isLoading = false
		return false
	}
},
locationReload = function(){
	removeCalculatedParams()
	window.location.reload()
}