import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { $classes } from '@/main'
import DatabaseService from '@/services/Database.service.js'
import moment from 'moment'
import _ from 'lodash'
// import { tap } from 'rxjs/operators'

import { v4 as uuidv4 } from 'uuid'

// import {
// 	getParamsAlertValue
// } from '@/functions/paramsOffline'

@Module({ namespaced: true })
class CurrentStays extends VuexModule {
	triage_timers = {}
	immobility_timers = {}
	dashboard_data = {}
	stay_list = []
	stay_list_zwr = []
	// stay_orders_list = []
	stay_notifications_list = []
	// difference_list = [];
	prev_stay_list = []
	dashboard_config = {
		// selected_stay_id: null,
		// list_tab_id: 1,
		// content_tab_id: 1,
		// selected_examination_id: null,
		// selected_treatment_id: null,
		// selected_stay_order_id: null,
		// stay_list_sort_option: {
		//     id: 0,
		//     name: "triage",
		//     label: "Kolor triaż",
		//     enabled: false,
		//     mode: "asc", // asc or desc
		// },
		// diagnostic_sort_option: { name: "examination_date", label: "Data usługi", direction: "desc" },
		// treatment_sort_option: { name: "creation_date", label: "Data utworzenia", direction: "desc" },
		selected_theme: 'light',
		// new_stays_count: 0,
		// order_office_filter: {name: 'Wszystkie', code: null, type: null},
		// stay_office_filter: {name: 'Wszystkie', code: null, type: null},
		// stay_search_str: "",
		// stay_count: 0,

		/*
            hospanel
        */

		// modal
		modal_new_note_opened: false,
		modal_new_note_edit_mode: false,
		modal_new_note_edit_data: null,

		modal_raport_opened: false,
		modal_raport_data: null,

		modal_profile_image_opened: false,
		modal_profile_image_data: null,

		// notifications
		notifications: [],

		// show period
		show_period_params: process.env.VUE_APP_DEV_PERIOD_PARAMS
			? Number(process.env.VUE_APP_DEV_PERIOD_PARAMS)
			: null,
		show_period_short_params: process.env.VUE_APP_DEV_PERIOD_SHORT_PARAMS
			? Number(process.env.VUE_APP_DEV_PERIOD_SHORT_PARAMS)
			: null,
		show_period_drugs: process.env.VUE_APP_DEV_PERIOD_PARAMS
			? Number(process.env.VUE_APP_DEV_PERIOD_PARAMS)
			: null,
		show_period_examinations: process.env.VUE_APP_DEV_PERIOD_EXAMINATIONS
			? Number(process.env.VUE_APP_DEV_PERIOD_EXAMINATIONS)
			: null,
		show_period_descriptive_data: process.env
			.VUE_APP_DEV_PERIOD_DESCRIPTIVE_DATA
			? Number(process.env.VUE_APP_DEV_PERIOD_DESCRIPTIVE_DATA)
			: null,

		limit_examinations_per_stay: process.env
			.VUE_APP_DEV_LIMIT_EXAMINATIONS_PER_STAY
			? Number(process.env.VUE_APP_DEV_LIMIT_EXAMINATIONS_PER_STAY)
			: null,
		limit_descriptive_data_per_stay: process.env
			.VUE_APP_DEV_LIMIT_DESCRIPTIVE_DATA_PER_STAY
			? Number(process.env.VUE_APP_DEV_LIMIT_DESCRIPTIVE_DATA_PER_STAY)
			: null,

		// dev
		dev_datetime: /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/g.test(
			process.env.VUE_APP_DEV_DATE
		)
			? process.env.VUE_APP_DEV_DATE
			: null,

		selected_unit: null,
		stay_list_sort_option: null,
		zwr_tab_option: null,
		zwr_sort_option: null,
		descriptive_data_sort_option: {
			code: 'creation_date',
			type: 'date',
			title: 'Data utworzenia',
			direction: 'desc',
		},
		notes_sort_option: {
			code: 'date_created',
			type: 'date',
			direction: 'desc',
		},

		panel_default_redirect: 'stays', // stays, zwr
		panel_prev_url: null,
	}
	data_sync_time = null
	data_sync_interval = 60000
	data_sync_interval_runner = 10000
	data_sync_code = null

	// unit_list = []

	@Mutation
	_setTriageTimers(payload) {
		// this.triage_timers = payload;
		this.triage_timers = _.merge({}, this.triage_timers, payload)
	}
	@Action({ rawError: true })
	async setTriageTimers(payload) {
		this.context.commit('_setTriageTimers', payload)
	}

	@Mutation
	_setImmobilityTimers(payload) {
		this.immobility_timers = _.merge({}, this.immobility_timers, payload)
	}
	@Action({ rawError: true })
	async setImmobilityTimers(payload) {
		this.context.commit('_setImmobilityTimers', payload)
	}

	@Mutation
	setDifferenceList(list) {
		this.difference_list = list
	}

	@Mutation
	setDashboardDataSyncCode(code) {
		this.data_sync_code = code
	}

	// @Mutation
	// setDashboardData(data) {
	// 	this.prev_stay_list = _.cloneDeep(this.stay_list) // deep copy
	// 	this.stay_list = data.stay_list || []
	// 	this.stay_orders_list = data.stay_orders_list || []
	// 	this.stay_notifications_list = data.stay_notifications_list || []
	// 	this.data_sync_time = moment()
	// }

	@Mutation
	setDashboardData(data) {
		if (data?.stay_list) this.stay_list = data.stay_list || []
		if (data?.stay_orders_list)
			this.stay_orders_list = data.stay_orders_list || []
		if (data?.stay_notifications_list)
			this.stay_notifications_list = data.stay_notifications_list || []

		this.data_sync_time = moment()
	}

	@Mutation
	pushToStayList(data) {
		//TODO improve data merge
		let ids = this.stay_list.map((e) => e.__id),
			newStays = [...data.stay_list]
				.filter((i) => {
					return ids.indexOf(i.__id) == -1
				})
				.map((e) => {
					return {
						...e,
						mustFetchAllData: data.mustFetchAllData,
					}
				}),
			oldStays = data.stay_list.filter((i) => {
				return ids.indexOf(i.__id) > -1
			})

		oldStays.forEach((s) => {
			let i = ids.findIndex((e) => e == s.__id)

			if (i > -1) {
				let oldCopy = { ...this.stay_list[i] },
					params = []
				if (typeof s?.params?.length !== 'undefined')
					params =
						oldCopy?.params?.length > s?.params?.length
							? oldCopy?.params
							: s?.params
				else params = oldCopy?.params

				this.stay_list[i] = {
					...this.stay_list[i],
					...s,
					mustFetchAllData: data.mustFetchAllData,
					params: params,
					// params: data.mustFetchAllData ? oldCopy?.params : s?.params /* needed improvement */
				}
			}
		})

		this.stay_list = [...this.stay_list, ...newStays]

		this.data_sync_time = moment()
	}

	// @Mutation
	// setUnitsData(data) {
	// 	this.unit_list = data
	// }

	@Mutation
	setDashboardOrders(orders) {
		this.stay_orders_list = orders || []
	}

	@Mutation
	_setDashboardConfig(payload) {
		this.dashboard_config = _.merge({}, this.dashboard_config, payload)
	}

	@Mutation
	setNotificationList(list) {
		this.stay_notifications_list = list || []
	}

	@Mutation
	setStayListUpdateOne(data) {
		let { stay_id, property, newData } = data

		this.stay_list[this.stay_list.findIndex((e) => e.id === stay_id)][
			property
		] = newData
	}

	@Action({ rawError: true })
	async getDifferenceList() {
		try {
			const difference_list =
				await $classes.DifferenceItem.get_difference_list()
			this.context.commit('setDifferenceList', difference_list)
		} catch (e) {
			console.log(e.response)
		}
	}
	@Action({ rawError: true })
	async setDifferencesAsDisplayed(ids) {
		try {
			const difference_list =
				await $classes.DifferenceItem.set_differences_as_displayed({
					ids: ids,
				})
			this.context.commit('setDifferenceList', difference_list)
		} catch (e) {
			console.log(e.response)
		}
	}

	@Action({ rawError: true })
	async setDashboardConfig(payload) {
		this.context.commit('_setDashboardConfig', payload)
	}

	// @Action({rawError: true})
	// async update_order_status(payload) {
	//     const data = await $classes.StayOrder.update_status(payload);
	//     if(data.status == 'ok'){
	//         const data = await $classes.Person.gui_dashboard_info();
	//         this.context.commit('setDashboardData', data);
	//     }
	// }

	@Action({ rawError: true })
	async create_orders(payload) {
		const data = await $classes.StayOrder.create_orders(payload)
		if (data.status == 'ok') {
			// const data = await $classes.Person.gui_dashboard_info();
			// this.context.commit('setDashboardData', data);

			const orders = await $classes.Person.gui_get_active_orders()
			this.context.commit('setDashboardOrders', orders)
		}
	}

	@Action({ rawError: true })
	async get_units_data() {
		try {
			const data = await $classes.OrgUnit.get_list()

			this.context.commit('setUnitsData', data)

			return data
		} catch (e) {
			console.log(e)
		}
	}

	@Action({ rawError: true })
	async create_or_update_import_request(payload) {
		try {
			const data =
				await $classes.PatientDataRequest.create_or_update_import_by_org_unit_code(
					{
						org_unit_code: payload.org_unit_code,
						priority: payload.priority,
						create_if_empty: payload.create_if_empty,
					}
				)
			return data
		} catch (e) {
			console.log(e)
		}
	}

	@Action({ rawError: true })
	async get_import_requests(payload) {
		if (!$classes)
			console.arror(
				'Unable to "get_import_requests", classes is not loaded.'
			)

		try {
			const data =
				await $classes.PatientDataRequest.get_imports_by_org_unit_code({
					org_unit_code: payload.org_unit_code,
					priority: 2,
				})
			return data
		} catch (e) {
			throw new Error(e)
		}
	}
	
	@Action({ rawError: true })
	async get_imports_by_org_unit_codes(payload) {
		if (!$classes)
			console.arror(
				'Unable to "get_import_requests", classes is not loaded.'
			)

		try {
			const data =
				await $classes.PatientDataRequest.get_imports_by_org_unit_codes({
					org_unit_codes: payload.org_unit_codes,
					priority: 2,
				})
			return data
		} catch (e) {
			throw new Error(e)
		}
	}
	

	/*
    @Action({rawError: true})
    async monitoringOrderModify(payload) {
        await $classes.Stay.monitoring_order_modify({
            'status': payload.status,
            'id': payload.stay_id
        });

        this.context.commit('setStayListUpdateOne', {
            stay_id: payload.stay_id,
            property: 'is_monitoring_order',
            newData: payload.status
        });
    }
    */

	@Action({ rawError: true })
	async stayStateModify(payload) {
		let res = await $classes.Stay.stay_status_modify({
			state: payload.state,
			stay_id: payload.stay_id,
		})

		if (res?.stay) {
			this.context.commit('pushToStayList', {
				stay_list: [res.stay],
			})
		}
	}

	//TODO
	@Action({ rawError: true })
	async stayStateModifyOffline(payload) {
		console.debug(payload)
	}

	@Action({ rawError: true })
	async noteCreate(payload) {
		console.debug(payload)

		await $classes.StayNote.note_create({
			stay_id: payload.stay_id,
			text: payload.text,
			visibility: payload.visibility,
			files: payload.files,
		})
	}

	@Action({ rawError: true })
	async noteCreateOffline(payload) {
		console.debug(payload)

		//TODO

		const obj = {
			couch_uuid: uuidv4(),

			person: '',
			stay: '',
			date_created: '',
			text: '',
			// files: '',
			// visibility: '',
		}
		//TODO add files and visibility as objects to other collections

		console.dir(obj)

		const $db = (await DatabaseService.singleton()).db
		await $db.stay_notes.insert(obj)
	}

	@Action({ rawError: true })
	async getStayNotes(payload) {
		const stay_notes = await $classes.StayNote.get_list({
			stay_id: payload.stay_id,
		})

		this.context.commit('setStayListUpdateOne', {
			stay_id: payload.stay_id,
			property: 'stay_notes',
			newData: stay_notes,
		})
	}

	@Action({ rawError: true })
	async getExaminationResults(payload) {
		try {
			const res = await $classes.Examination.get_results({
				ids: payload.ids,
			})

			let t = { ...res }

			Object.keys(t).map((e) => {
				t[e] = t[e]?.[0]?.['attribute'].filter(
					(e) => e.type == 'NUMBER' && !!e.value
				)
			})

			this.context.commit('setStayListUpdateOne', {
				stay_id: payload.stay_id,
				property: 'results_download_before',
				newData: t,
			})

			return t
		} catch (e) {
			console.log(e)
		}
	}

	// TODO to delete
	@Action({ rawError: true })
	async get_dashboard_data_for_zwr_offline() {
		try {
			console.time(
				`Offline process stays method="get_dashboard_data_for_zwr_offline"`
			)

			const $db = (await DatabaseService.singleton()).db
			const query = $db.stays.find()
			const results = await query.exec()

			// title: filtrujesz results wzorując się na gui_zwr_list oraz getParamsFromExaminations

			// filter po wynikach
			// sprawdzasz czy is_monitoring_order = true ? return true else patrz ponizej
			// sprawdzasz czy getParamsAlertValue = true ? return true else false

			console.debug(results)

			this.context.commit('setDashboardData', { stay_list_zwr: results })

			console.timeEnd(
				`Offline process stays method="get_dashboard_data_for_zwr_offline"`
			)
		} catch (e) {
			if (e.response) {
				let code = e.response.status || 500
				this.context.commit('setDashboardDataSyncCode', code)
			} else {
				console.log(e)
			}
		}
	}

	@Action({ rawError: true })
	async get_dashboard_data_for_zwr() {
		try {
			console.debug(`Fetch stay_list for zwr started...`)
			const t0 = Date.now()

			const data = await $classes.Stay.gui_zwr_list()

			console.debug(
				`Fetch stay_list ended. time: ${Date.now() - t0} miliseconds.`
			)

			this.context.commit('pushToStayList', {
				...data,
				mustFetchAllData: true,
			})
			this.context.commit('setDashboardDataSyncCode', 200)

			console.debug(`Reset selected unit data.`)

			this.context.commit('_setDashboardConfig', {
				...this.dashboard_config,
				selected_unit: null,
			})
		} catch (e) {
			if (e.response) {
				let code = e.response.status || 500
				this.context.commit('setDashboardDataSyncCode', code)
			} else {
				console.debug(e)
			}
		}
	}

	@Action({ rawError: true })
	async get_dashboard_data_for_single_stay(payload) {
		try {
			console.debug(`Fetch single stay started...`)
			const t0 = Date.now()

			const data = await $classes.Stay.gui_stay_info({
				stay_id: payload.stay_id,
			})

			console.debug(
				`Fetch single stay ended. time: ${Date.now() - t0} miliseconds.`
			)

			this.context.commit('pushToStayList', {
				...data,
				mustFetchAllData: false,
			})
			this.context.commit('setDashboardDataSyncCode', 200)
		} catch (e) {
			if (e.response) {
				let code = e.response.status || 500
				this.context.commit('setDashboardDataSyncCode', code)
			} else {
				console.log(e)
			}
		}
	}

	@Action({ rawError: true })
	async get_dashboard_data_for_single_stay_offline(payload) {
		try {
			console.time(
				`Offline process stay method="get_dashboard_data_for_single_stay"`
			)

			const $db = (await DatabaseService.singleton()).db
			const query = $db.stays.findOne({
				selector: { stay_id: payload.stay_id },
				sort: [{ couch_uuid: 'asc' }],
			})
			const result = await query.exec()

			console.timeEnd(
				`Offline process stay method="get_dashboard_data_for_single_stay"`
			)

			this.context.commit('pushToStayList', {
				...result,
				mustFetchAllData: false,
			})
		} catch (e) {
			console.log(e)
		}
	}

	@Action({ rawError: true })
	async updatePatientProfileImage(payload) {
		const req = await $classes.Patient.upload_profile_image(payload)

		this.context.commit('setStayListUpdateOne', {
			stay_id: payload.stay_id,
			property: 'patient_profile_image_id',
			newData: req.patient_profile_image_id,
		})
		this.context.commit('setStayListUpdateOne', {
			stay_id: payload.stay_id,
			property: 'patient_profile_image_hash',
			newData: req.patient_profile_image_hash,
		})
	}
}

export default CurrentStays
