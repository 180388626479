import { reloadWindow } from '@/functions/cache.js'

export const resolveError = function () {
	const error = arguments[0],
		{ message, name } = error

	if (typeof name !== 'undefined') {
		let resolveByReload = false

		if (name === 'ChunkLoadError') // PT-1167
			resolveByReload = true
		// else if( name === 'Error' && message === 'Request failed with status code 400') // PT-1230
		// 	resolveByReload = true
		else if(message == `Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.`) // PT-1471
			resolveByReload = true
		else if(message == `Database has a global failure`) // PT-1243
			resolveByReload = true
		
		if(resolveByReload /* || ... */) {
			console.debug(`[resolveError] znaleziono rozwiązanie dla erroru '${name}' z wiadomością '${message}' - ${resolveByReload ? 'przeładowanie strony' : '???'}`) //TODO
		}
		
		if(resolveByReload){
			reloadWindow()
		}
	}
}
