export const getAsBool = function (name = null) {

	if (name){
	    const v = (
			JSON.parse(process.env?.[name]?.toLowerCase() || 'false') === true
		)
		
        // console.debug(`[getAsBool] name: ${name}`, v)
		
		return v
	}

	return undefined
}
