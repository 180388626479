import { aes, md5 } from '@/functions/encryption'

import { createEventFromCode } from '@/functions/events'

import { $classes } from '@/main'

import { isConnectionReady } from '@/functions/network'
import { getCircularReplacer } from '@/functions/json'

let _getTokenPromise = null,
	_renewTokenPromise = null
	
export const userPassHashKey = 'rxdb-encryption-user-pass-hash',
	userPassHashFromHashKey = 'rxdb-encryption-user-pass-hash-from-hash',
	passwordEncryptedKey = 'rxdb-encryption-rxdb-password-encrypted'

export const key = 'user',
	_getToken = async function () {
		const auth = getAuth()

		if (!auth) return

		let { access_token_expire } = auth

		if (Date.now() < access_token_expire) return auth?.access_token
		else await createEventFromCode('auth-access-token-expired')

		const refresh_token = await getRefreshToken()

		if (refresh_token) {
			const access_token = renewToken(refresh_token)

			return access_token
		}
	},
	getToken = async function () {
		if (_getTokenPromise) return await _getTokenPromise
		else {
			_getTokenPromise = _getToken()
			const access_token = await _getTokenPromise
			_getTokenPromise = null
			return access_token
		}
	},
	isClassesReady = async function () {
		if (!$classes?.AuthService) {
			console.debug(`[isClassesReady] Getting public classes...`)

			try {
				await $classes.loadPublic()
			} catch (e) {
				await createEventFromCode('fetch-classes-public-fail')
				
				throw e
			}
		}
	},
	getTokenBy = async function (data, grant_type = 'password') {
		console.debug(`[getTokenBy] starting...`)
		
		if (grant_type === 'password') {
			const { login, pass } = data
			
			await isConnectionReady()
			await isClassesReady()

			return await $classes.AuthService.token({
				grant_type: 'password',
				username: login,
				password: pass,
				scope: 'provider:Person',
			})
		}

		return null
	},
	getRefreshToken = async function () {
		const auth = getAuth()

		if (!auth) return

		let { refresh_token_expire } = auth

		if (Date.now() < refresh_token_expire) return auth?.refresh_token
		else await createEventFromCode('auth-refresh-token-expired')
	},
	_renewToken = async function (refresh_token_old) {
		console.debug(`[renewToken] starting...`)

		await isConnectionReady()
		await isClassesReady()
		
		try {
			const { access_token, refresh_token, expires_in, refresh_expires_in } =
				await $classes.AuthService.token({
					grant_type: 'refresh_token',
					refresh_token: refresh_token_old,
					scope: 'provider:Person',
				})
	
			setAuth({ access_token, refresh_token, expires_in, refresh_expires_in })
			
			await createEventFromCode('auth-refresh-token-renew-success')
			
			return access_token
		} catch (e) {
			console.error({ ...e })
			
			await createEventFromCode('auth-refresh-token-renew-fail')
		}

	},
	renewToken = async function (refresh_token_old) {
		if (_renewTokenPromise) return await _renewTokenPromise
		else {
			_renewTokenPromise = _renewToken(refresh_token_old)
			const access_token = await _renewTokenPromise
			_renewTokenPromise = null
			return access_token
		}
	},
	getAuth = function () {
		const info = getUser()

		return info?.auth
	},
	getUser = function () {
		let info = null

		if (sessionStorage.getItem(key))
			info = JSON.parse(sessionStorage.getItem(key))
		else if (localStorage.getItem(key))
			info = JSON.parse(localStorage.getItem(key))

		return info
	},
	setAuth = function (auth) {
		console.debug(`[setAuth] saving...`)

		const now = Date.now(),
			access_token_expire = now + auth.expires_in * 1000,
			refresh_token_expire = now + auth.refresh_expires_in * 1000
		// format = (date) =>
		// 	moment(date).locale('pl').format('DD-MM-YYYY HH:mm:ss')

		const info = getUser(),
			infoNew = {
				...info,
				auth: {
					...auth,
					access_token_expire: access_token_expire,
					access_token_created: now,
					refresh_token_expire: refresh_token_expire,
					refresh_token_created: now,
				},
			}
		let dataStringified = JSON.stringify(infoNew, getCircularReplacer())

		/* localstorage */
		localStorage.setItem(key, dataStringified)
	},
	is_hash_exists = function () {
		let userPassHashDouble = localStorage.getItem(
			userPassHashFromHashKey
		)

		if (userPassHashDouble) return true

		return false
	},
	is_hash_user_pass_hash_exists = function () {
		let userPassHash = sessionStorage.getItem(
			userPassHashKey
		)

		if (userPassHash) return true

		return false
	},
	compare_user_password = function ({ userPassPlain, userEmailPlain }) {
		if (!is_hash_exists()) return null

		let userPassHashDouble = localStorage.getItem(
				userPassHashFromHashKey
			),
			userPassHash = sessionStorage.getItem(
				userPassHashKey
			)

		if (userPassPlain && userEmailPlain) {
			console.debug(
				`[compare_user_password] getting user password hash from passed plain password`
			)

			userPassHash = md5.encrypt(`${userPassPlain}${userEmailPlain}`)
		} else
			console.error(
				`[compare_user_password] user access data no complete. ${
					!userEmailPlain ? 'login' : 'password'
				} is missing.`
			)

		if (!userPassHash || !userPassHashDouble) {
			console.error(`[compare_user_password] not enough data`)

			return null
		}

		if (md5.encrypt(userPassHash) == userPassHashDouble) return true
		else return false
	},
	set_RxDB_auth = async function ({
		userPassPlain,
		userEmailPlain,
		rxdbPasswordPlain,
	}) {
		if (!userPassPlain || !rxdbPasswordPlain || !userEmailPlain) {
			console.debug(
				`[setRxDbAuth] no password of user or rxdb or user login`
			)

			return
		}

		console.debug(`[setRxDbAuth] saving rxdb auth config data`)

		try {
			let userPassHash = md5.encrypt(`${userPassPlain}${userEmailPlain}`),
				userPassHashDouble = md5.encrypt(userPassHash),
				rxdbPasswordEncrypted = aes.encrypt(
					rxdbPasswordPlain,
					userPassHash
				)

			localStorage.setItem(
				passwordEncryptedKey,
				rxdbPasswordEncrypted
			)
			localStorage.setItem(
				userPassHashFromHashKey,
				userPassHashDouble
			)
			sessionStorage.setItem(
				userPassHashKey,
				userPassHash
			)
		} catch (error) {
			console.debug(error)
		}
	},
	// Task: https://etrust.atlassian.net/browse/PT-1098
	// Simulate expiring
	simulate_expire_access_token = function () {
		const auth = getAuth()

		console.debug(`[simulate_expire_access_token] access_token expired`)

		setAuth({ ...auth, expires_in: 0 })
	},
	simulate_expire_refresh_token = function () {
		const auth = getAuth()

		console.debug(`[simulate_expire_refresh_token] refresh_token expired`)

		setAuth({ ...auth, refresh_expires_in: 0 })
	},
	is_auth_not_expired = function () {
		const auth = getAuth()

		if (auth?.refresh_token_expire >= Date.now()) return true

		return false
	},
	clear_auth_data = function (){
		sessionStorage.removeItem(userPassHashKey)
	}

export default getToken
