Object.byString = function (o, s) {
	s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
	s = s.replace(/^\./, '') // strip a leading dot

	let a = s.split('.')
	for (let i = 0, n = a.length; i < n; ++i) {
		let k = a[i]
		if (k in (o || {})) {
			o = o[k]
		} else {
			return
		}
	}
	return o
}

export const compareDates = function (a, b) {
		return Date.parse(a) - Date.parse(b)
	},
	compareStrings = function (a, b) {
		return `${a}`.localeCompare(`${b}`)
	},
	compareInteger = function (a, b) {
		a = parseInt(a)
		b = parseInt(b)
		
		return (a || 0) - (b || 0)
	},
	compareDictionary = function (a, b, order, orderItemKey) {
		if (a == b) return 0

		return (order.findIndex((e) => e[orderItemKey] == a) || 0) >
			(order.findIndex((e) => e[orderItemKey] == b) || 0)
			? -1
			: 1
	},
	getProperty = function (obj, string) {
		return string &&
			string !== 'undefined' &&
			obj &&
			typeof obj !== 'string'
			? Object.byString(obj, string)
			: obj
	},
	sortByConfigSkeleton = {
		code: null,
		title: null,
		type: null,
		direction: 'asc',
		orderItemKey: null,
		order: [],
	},
	compareBy = function (a = null, b = null, items = []) {
		const primary = items?.shift(),
			{ type, code, code_2, order, orderItemKey, direction } = primary

		let result = null

		if (type === 'date') {
			result = compareDates(
				getProperty(a, `${code}`),
				getProperty(b, `${code}`)
			)
		} else if (type === 'string') {
			const s1 = `${getProperty(a, `${code}`)}${getProperty(
					a,
					`${code_2}`
				)}`,
				s2 = `${getProperty(b, `${code}`)}${getProperty(
					b,
					`${code_2}`
				)}`

			result = compareStrings(s1, s2)
		} else if (type === 'int') {
			result = compareInteger(
				getProperty(a, `${code}`),
				getProperty(b, `${code}`)
			)
		} else if (type === 'dictionary')
			result = compareDictionary(
				getProperty(a, `${code}`),
				getProperty(b, `${code}`),
				order,
				orderItemKey
			)

		if (result === null) return 0

		const last = items?.length > 0 ? items[0] : null

		if (result != 0 && direction === 'desc') result *= -1
		else if (
			result === 0 &&
			last &&
			(type !== last.type ||
				code !== last.code ||
				code_2 !== last.code_2 ||
				code_2 !== last.code_2 ||
				orderItemKey !== last.orderItemKey ||
				direction !== last.direction ||
				order !== last.order)
		)
			return compareBy(a, b, items)

		return result
	},
	sortArrByMulti = function (arr = [], sortBy = [sortByConfigSkeleton]) {
		if (
			sortBy &&
			arr instanceof Array &&
			sortBy &&
			sortBy instanceof Array
		) {
			if (arr?.length < 2) return arr
			
			// console.debug(arr)

			const consoleMessage = `[sortArrByMulti] sorting array of ${
				arr?.length
			} objects by ${JSON?.stringify(sortBy)}`
			console.time(consoleMessage)

			let a = [...arr]

			a = a.sort((a, b) => {
				return compareBy(a, b, [...sortBy])
			})

			console.timeEnd(consoleMessage)

			return a
		}
	},
	sortArrBySingle = function (arr = [], sortBy = sortByConfigSkeleton) {
		if (sortBy && arr instanceof Array && arr?.length > 0) {
			if (!sortBy?.type) throw new Error(`[sortArrBySingle] no type`)

			let a = [...arr]

			if (sortBy?.code && sortBy?.type) {
				const consoleMessage = `[sortArrBySingle] sorting array of ${arr?.length} objects by code "${sortBy?.code}", which is type "${sortBy?.type}"`
				console.time(consoleMessage)
				if (sortBy?.type === 'date')
					a = a.sort((a, b) => {
						return compareDates(
							getProperty(a, `${sortBy.code}`),
							getProperty(b, `${sortBy.code}`)
						)
					})
				else if (sortBy?.type === 'string')
					a = a.sort((a, b) => {
						const s1 = `${getProperty(
								a,
								`${sortBy.code}`
							)}${getProperty(a, `${sortBy.code_2}`)}`,
							s2 = `${getProperty(
								b,
								`${sortBy.code}`
							)}${getProperty(b, `${sortBy.code_2}`)}`

						return compareStrings(s1, s2)
					})
				else if (sortBy?.type === 'int')
					a = a.sort((a, b) => {
						return compareInteger(
							getProperty(a, `${sortBy.code}`),
							getProperty(b, `${sortBy.code}`)
						)
					})
				else if (sortBy.type === 'dictionary')
					a = a.sort((a, b) => {
						return compareDictionary(
							getProperty(a, `${sortBy.code}`),
							getProperty(b, `${sortBy.code}`),
							sortBy.order,
							sortBy.orderItemKey
						)
					})
				console.timeEnd(consoleMessage)
			} else if (sortBy?.type) {
				const consoleMessage = `[sortArrBySingle] sorting array of ${arr?.length} data by code "${sortBy?.code}", which is type "${sortBy?.type}"`
				console.time(consoleMessage)
				// code not exist
				if (sortBy?.type === 'date')
					a = a.sort((a, b) => {
						return compareDates(a, b)
					})
				console.timeEnd(consoleMessage)
			}

			if (sortBy?.direction === 'desc') a = a.reverse()

			return a
		} else return arr
	},
	sortArrBy = function (arr = [], sortBy = null) {
		if (sortBy !== null) {
			if (sortBy instanceof Array) {
				return sortArrByMulti(arr, sortBy)
			}

			return sortArrByMulti(arr, [sortBy])
		}

		console.error('[sortArrBy] no sort by config', { arr, sortBy })

		return arr
	},
	stayNoteFilesSortConfig = {
		code: 'title',
		type: 'string',
		direction: 'asc',
	}

export default sortArrBy
