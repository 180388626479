import moment from 'moment'

import store from '@/store'

import { statusesCancelled } from '@/functions/styleByStatus'

const isValidDataProperty = (dataProperty) => {
		const dataPropertyOptions = [
			'application_date',
			'order_date',
			'examination_date',
			'show_period_examinations',
			'show_period_params',
			'show_period_drugs',
			'date_created',
			'show_period_short_params',
			'creation_date',

			// events
			'datetimeCreated',
		]

		return dataPropertyOptions.includes(dataProperty)
	},
	isValidUnit = (unit) => {
		const unitOptions = ['days', 'hours', 'minutes', 'seconds']

		return unitOptions.includes(unit)
	}

export const limitObjects = (
		x,
		dataProperty,
		peroid,
		unit = 'days',
		disableDevDatetime = false
	) => {
		if (!isValidDataProperty(dataProperty)) {
			console.error(
				`[limitObjects]: No support for selected dataProperty="${dataProperty}" option`
			)
		}

		if (!isValidUnit(unit)) {
			console.error(
				`[limitObjects]: No support for selected unit="${unit}" option`
			)
		}

		const devDatetime =
			store?.state?.CurrentStays?.dashboard_config?.dev_datetime ?? null

		return x && x.length > 0
			? x.filter(
					(e) =>
						(devDatetime && !disableDevDatetime
							? moment(devDatetime)
							: moment()
						).diff(moment(e[dataProperty]), unit) <=
						(store?.state?.CurrentStays?.dashboard_config?.[
							peroid
						] || peroid)
			  )
			: []
	},
	getDateFromPast = function (
		peroid = 0,
		unit = 'days',
		disable_dev_datetime = false
	) {
		let dev_datetime =
			store?.state?.CurrentStays?.dashboard_config?.dev_datetime ?? null

		return (
			dev_datetime && !disable_dev_datetime
				? moment(dev_datetime)
				: moment()
		)
			.subtract(
				store?.state?.CurrentStays?.dashboard_config?.[peroid] ??
					peroid,
				unit
			)
			.format('YYYY-MM-DD HH:mm:ss.000000+01:00') // 2023-01-16 05:41:00.000000+01:00
		//.toDate()
	},
	getFilterForCollection = function (collectionName = null, range = 'all') {
		if (!collectionName)
			throw new Error(`[getFilterForCollection] no collectionName`)

		//TODO add collection services
		const paramsSelector = {
				result: { $nin: [null] },
				gui_type: 'params',
			},
			paramsOnlyNEWS2Selector = {
				...paramsSelector,
				// result: /NEWS/,
				result_form_contain_news: true,
			},
			noParamsSelector = {
				$not: { gui_type: 'params' },
			},
			config = [
				{
					collectionName: 'examinations',
					range: 'all',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: {
						status_name: { $nin: statusesCancelled },
					},
				},
				{
					collectionName: 'examinations',
					range: 'params-o',
					filterDate: {
						dataProperty: 'order_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'all-wihout-params',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: noParamsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'params-o-news',
					filterDate: {
						dataProperty: 'examination_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsOnlyNEWS2Selector,
				},
				{
					collectionName: 'examinations',
					range: 'params-e',
					filterDate: {
						dataProperty: 'examination_date',
						peroid: 'show_period_params',
						unit: 'days',
					},
					selectorRaw: paramsSelector,
				},
				{
					collectionName: 'examinations',
					range: 'lab-o',
					filterDate: {
						dataProperty: ['examination_date', 'order_date'],
						peroid: 'show_period_examinations',
						unit: 'days',
					},
					selectorRaw: {
						gui_type: { $in: ['laboratory'] },
					},
				},
				{
					collectionName: 'drug_applications',
					range: 'all',
					filterDate: {
						dataProperty: ['application_date', 'order_date'],
						peroid: 'show_period_drugs',
						unit: 'days',
					},
					selectorRaw: {
						order_date: { $gt: true }, // Note that sorted fields also have to be selected in the selector.
					},
				},
				{
					collectionName: 'treatments',
					range: 'all',
					filterDate: {
						dataProperty: ['creation_date', 'modification_date'],
						peroid: 'show_period_descriptive_data',
						unit: 'days',
					},
				},
				{
					collectionName: 'treatments',
					range: 'only-rozpoznanie-wstepne',
					filterDate: {
						dataProperty: 'creation_date', //['creation_date',/* 'modification_date' */],
						peroid: 'show_period_descriptive_data',
						unit: 'days',
					},
					selectorRaw: {
						name: { $regex: '^Rozpoznanie wstępne( .*)?$' },
						/*name: { $nin: [null] },
						$or: [
							{ name: 'Rozpoznanie wstępne' },
							{
								name: 'Rozpoznanie wstępne F/PZ-LiH-01/Z10/E001 - 06 - Historia Choroby Ogólna',
							},
						],*/
						//TODO use regex?
						// name: /Rozpoznanie wstępne/
					},
				},
				{
					collectionName: 'org_units',
					range: 'only_active',
					selectorRaw: {
						is_active: true,
						is_assigned: true,
					},
				},
				{
					collectionName: 'stays',
					range: 'only_active',
					selectorRaw: {
						stop_date: null,
						patient_uuid: { $gt: true }, // not compatible with rxdb@10.5.4, for this version replace true with 0
					},
				},
				{
					collectionName: 'zwr_patient_alerts',
					range: 'only_active',
					// filterDate: {
					// 	dataProperty: 'examination_date',
					// 	peroid: 'active_period_zwr_patient_alert',
					// 	unit: 'hours',
					// },
					selectorRaw: {
						is_active: true,
					},
				},
				{
					collectionName: 'org_unit_persons',
					range: 'only_for_logged_user',
					selectorRaw: {
						person_id_ref: store?.state?.CurrentUser?.instance?.id,
					},
				},
			]

		let i = config.findIndex((e) =>
			e.collectionName === collectionName && e?.range === range
				? true
				: false
		)

		if (i === -1)
			throw new Error(
				`[getFilterForCollection] no config for collection '${collectionName}' and range '${range}'`
			)
		else {
			const { filterDate, selectorRaw } = config[i] || {},
				selector = { ...selectorRaw }

			if (filterDate?.dataProperty) {
				if (typeof filterDate?.dataProperty == 'string')
					selector[filterDate.dataProperty] = {
						$gte: getDateFromPast(
							filterDate.peroid,
							filterDate.unit
						),
					}
				else if (typeof filterDate?.dataProperty == 'object') {
					const or = filterDate?.dataProperty.map((dP) => ({
						[dP]: {
							$gte: getDateFromPast(
								filterDate.peroid,
								filterDate.unit
							),
						},
					}))

					// const or = filterDate?.dataProperty.map((dP) => ({
					// 	$and: [
					// 		{
					// 			[dP]: {
					// 				$exists: true,
					// 			},
					// 		},
					// 		{
					// 			[dP]: {
					// 				$gte: getDateFromPast(
					// 					filterDate.peroid,
					// 					filterDate.unit
					// 				),
					// 			},
					// 		},
					// 	],
					// }))

					if (selector.$or && !selector.$and) {
						selector.$and = []

						selector.$and.push({
							$or: or,
						})
						selector.$and.push({
							$or: selector.$or,
						})

						delete selector.$or
					} else if (!selector.$or)
						selector.$or = [...(selector.$or || []), ...or]
				}
			}

			console.debug(
				`[getFilterForCollection]`,
				collectionName,
				range,
				selector
			)

			return selector
		}
	},
	getFirst = function (arr, amount) {
		return arr?.slice(0, amount) || arr
	},
	getDaysBetweenDates = function (startDate, endDate) {
		var now = startDate.clone(),
			dates = []

		while (now.isSameOrBefore(endDate)) {
			dates.push(now.format('YYYY-MM-DD'))
			now.add(1, 'days')
		}
		return dates
	},
	codeDesc = '__DESC__',
	isHideDescAttr = function (attributes = []) {
		// skip description
		const descIndex = attributes.findIndex((it) => it.code == codeDesc)

		let isHideDescAttrDecision = false

		if (descIndex > -1) {
			// count rows in description
			const descValue = attributes[descIndex].value,
				rows = descValue?.split('\r\n').filter((r) => r !== '')

			if (rows.length === attributes.length - 1) {
				// check rows in desc
				const items = attributes.filter((it) => it.code !== codeDesc)

				const regexes = []
				items.forEach((it) => {
					const regex = new RegExp(
						`${it.name}\\s.*${it.value}`,
						'gmi'
					) //as simple as possible

					regexes.push(regex)
				})

				let testPassed = true
				rows.forEach((r) => {
					if (regexes.filter((rx) => rx.test(r)).length !== 1)
						testPassed = false
				})

				if (testPassed) {
					isHideDescAttrDecision = true

					console.debug(
						`attr description hidden - a duplicate of the data in the rest of the parameters`
					)
				}
			}
		}

		return isHideDescAttrDecision
	}

export default limitObjects
