// import { limitObjects } from '@/functions/filters'
import { getFormattedDate } from '@/functions/params'
// eslint-disable-next-line no-unused-vars
import colors from '@/assets/scss/colors.scss'

export const enabledCodes = [
		' Akcja',
		'Aparat Tlenowy',
		'Aparat_Tlenowy',
		' Aparat_Tlenowy',
		'Asp1',
		'Asp2',
		'BMI',
		' BMI',
		'BP',
		' BP',
		'BPD',
		' BPD',
		'BPDLAST',
		' BPDLAST',
		'BPLAST',
		' BPLAST',
		'Bilans Płynów Dyżuru',
		'Bol',
		' Bol',
		'Ból',
		' Ból',
		'Cisn_rozkur',
		' Cisn_rozkur',
		' Cisn_rozkurcz',
		'Cisn_skurcz',
		' Cisn_skurcz',
		' Cisn_sred',
		' Ciąża',
		// 'DT',
		// ' DT',
		'Diureza',
		' Diureza',
		' Dlugosc',
		'Dobowy Bilans Płynów',
		'Dren 1',
		'Dren 1 opis',
		'Dren 2',
		'Dren 2 opis',
		'Dren 3',
		'Dren 3 opis',
		'Dren_1',
		'Dren_1_o',
		'Dren_2',
		'Dren_2_o',
		'Dren_3',
		'Dren_3_o',
		'Dren_4',
		'Dren_4_o',
		'Dren_5',
		'Dren_5_o',
		'Dren_6',
		'Dren_6_o',
		' Dzień_Ciąży',
		' Glikemia',
		'HA',
		' HA',
		'Hosp. w obszarze endemic',
		' Inne',
		' Komentarz',
		'Kontakt słowny',
		'Kontakt z SARS COV-2',
		' Kontakt_słowny',
		'Krwawienie',
		'Krążenie',
		'Liczba Oddechów',
		'Liczba_Oddechów',
		' Liczba_Oddechów',
		'Mechanizm urazu',
		' Metoda_pom',
		'Metoda_pomiaru_temp',
		' Metoda_pomiaru_temp',
		'Mobilność pacjenta',
		'NEWS2',
		' Nawrót_Kapilarny',
		' Obwod_Kl',
		' Obwod_br',
		' Obwod_gl',
		'Oddech',
		' Oddech',
		'Oddychanie',
		'Osrodkowe_cis_zylne',
		' Osrodkowe_cis_zylne',
		'Otwieranie oczu',
		' Otwieranie_oczu',
		'PEG',
		' PEG',
		'PEG_J',
		' PEG_J',
		'PH_mocz',
		' PH_mocz',
		'PULS',
		' PULS',
		'Podaz_in_opis',
		' Podaz_in_opis',
		'Podaz_inne',
		' Podaz_inne',
		'Podaz_iv',
		' Podaz_iv',
		'Podaz_sonda',
		' Podaz_sonda',
		'Podaż inne',
		'Podaż inne opis',
		'Podaż_iv',
		'Podejrzenie SARS COV-2',
		' Poród',
		'Pow czy tlen Skala 1',
		'Pow czy tlen Skala 2',
		'Powietrze czy Tlen Skala',
		'Powietrze_czy_Tlen',
		' Powietrze_czy_Tlen',
		'Przeb.Nieb.Inf.Bakt.Szp',
		'Puls',
		' Puls',
		'Punkt oceny',
		'Reakcja ruchowa',
		' Reakcja_ruchowa_',
		'Rodzaj urazu',
		'Rozkurczowe Ciśnienie',
		'Rozkurczowe_Ciśnienie',
		' Rozkurczowe_Ciśnienie',
		'Rytm_serca',
		' Rytm_serca',
		'STOLEC',
		' STOLEC',
		'SUM_podazy',
		' SUM_podazy',
		'SUM_utraty',
		' SUM_utraty',
		' Saturacja',
		'Saturacja_z',
		' Saturacja_z',
		'Skurczowe Ciśnienie',
		'Skurczowe_Ciśnienie',
		' Skurczowe_Ciśnienie',
		'Sonda zgłęb nos.-żoł. Up',
		'Sonda zgłęb nos.-żoł. pp',
		'SpO2',
		'Stan świadomości',
		'Stan świadomości Skala 1',
		'Stan świadomości Skala 2',
		'Stan_świadomości',
		' Stan_świadomości',
		'Stolec',
		' Stolec',
		'Stomia',
		' Stomia',
		'Sugerowany kolor',
		'Sytuacje specjalne',
		'TEMP',
		' TEMP',
		'Temperatura',
		' Temperatura',
		' Temperatura_gl',
		'Terapia tlenowa',
		' Terapia_Tlenowa',
		' Tydzień_Ciąży',
		'Utrata inne',
		'Utrata inne opis',
		'Utrata_in_opis',
		' Utrata_in_opis',
		'Utrata_inne',
		' Utrata_inne',
		'Utrata_sonda',
		' Utrata_sonda',
		' WYN',
		// ' W_Rozkurczowe_Ciśnienie',
		// 'W_Skurczowe_Ciśnienie',
		// ' W_Skurczowe_Ciśnienie',
		'Waga',
		' Waga',
		'Wskaźnik masy ciała',
		'Wymioty',
		' Wymioty',
		// 'Wyn_Skala_PEWS_0-3_m-c',
		// 'Wyn_Skala_PEWS_12+_lat',
		// 'Wyn_Skala_PEWS_5-11_lat',
		// 'Wynik L Oddechów Skala 1',
		// 'Wynik L Oddechów Skala 2',
		// 'Wynik Liczba Oddechów Sk',
		'Wynik NEWS2 Skala 1',
		'Wynik NEWS2 Skala 2',
		// 'Wynik Pow czy tlenSkala1',
		// 'Wynik Pow czy tlenSkala2',
		// 'Wynik Powietrze czy Tlen',
		// 'Wynik Puls Skala 1',
		// 'Wynik Puls Skala 2',
		// 'Wynik Skurczowe Ciśnieni',
		// 'Wynik SpO2 Skala 1',
		// 'Wynik SpO2 Skala 2',
		// 'Wynik Stan świad. Skala1',
		// 'Wynik Stan świad. Skala2',
		// 'Wynik Temp Skala 2',
		// 'Wynik Temp Skala1',
		// 'Wynik Temperatura Skala',
		// 'Wynik skurcz cis Skala1',
		// 'Wynik skurcz cis Skala2',
		// 'Wynik_Liczba_Oddechów',
		// ' Wynik_Liczba_Oddechów',
		'Wynik_NEWS2_Skala_1',
		'Wynik_NEWS2_Skala_2',
		// ' Wynik_Nawrót_Kapilarny',
		// 'Wynik_Powietrze_czy_Tlen',
		// ' Wynik_Powietrze_czy_Tlen',
		// 'Wynik_Puls',
		// ' Wynik_Puls',
		// ' Wynik_Skala_OEWS',
		// 'Wynik_SpO2',
		// 'Wynik_SpO2_Skala_1',
		// 'Wynik_SpO2_Skala_2',
		// 'Wynik_Stan_świadomości',
		// ' Wynik_Stan_świadomości',
		// 'Wynik_Temperatura',
		// ' Wynik_Temperatura',
		// ' Wynik_Terapia_Tlenowa',
		// ' Wynik_Wysiłek_oddechowy',
		' Wysiłek_oddechowy',
		'Wysokość',
		' Wysokość',
		'Wzrost',
		' Wzrost',
		' Zywienie_nat',
		' Zywienie_sztAparat_Tlenowy',
		'il_wymiotów',
		' il_wymiotów',
		'ilosc_st',
		' ilosc_st',
		'pom_wag',
		' pom_wag',
		'pom_wzr',
		' pom_wzr',
		'pp Bilans Płynów Dyżuru',
		'pp Dobowy Bilans Płynów',
		'rodzaj_st',
		' rodzaj_st',
		'stolec_ml',
		' stolec_ml',
		'Średnie ciśnienie tętnic',
		'średnie ciśnienie',
		'średnie_ciśnienie',
		' średnie_ciśnienie',
	],
	mergeCodes = [
		{
			code: 'Temperatura',
			params: [
				'Temperatura',
				'Wynik Temp Skala1',
				'Wynik Temp Skala 2',
				'Wynik Temperatura Skala 1',
				'Wynik Temperatura Skala',
				'TEMP',
			],
		},
		{
			code: 'Rozkurczowe Ciśnienie',
			params: ['Rozkurczowe Ciśnienie', 'Cisn_rozkur'],
		},
		{
			code: 'Skurczowe Ciśnienie',
			params: ['Skurczowe Ciśnienie', 'Cisn_skurcz'],
		},
		{
			code: 'SpO2',
			params: ['Saturacja_z', 'SpO2'],
		},
		{
			code: 'Puls',
			params: ['Puls', 'PULS'],
		},
		{
			code: 'Ból',
			params: ['Ból', 'Bol'],
		},
		{
			code: 'Liczba Oddechów',
			params: ['Oddech', 'Liczba Oddechów'],
		},
		{
			code: 'Wynik Liczba Oddechów',
			params: [
				'Wynik L Oddechów Skala 2',
				'Wynik Liczba Oddechów Sk',
				'Wynik L Oddechów Skala 1',
			],
		},
		{
			code: 'Stan świadomości',
			params: ['Stan świadomości Skala 1', 'Stan świadomości Skala 2'],
		},
		{
			code: 'NEWS2',
			params: [
				'NEWS2',
				'Wynik NEWS2 Skala 1',
				'Wynik NEWS2 Skala 2',
				'Wynik_NEWS2_Skala_1',
				'Wynik_NEWS2_Skala_2',
			],
		},
		{
			code: 'Powietrze czy Tlen',
			params: ['Powietrze czy Tlen', 'Wynik Powietrze czy Tlen'],
			transform: {
				code: {
					from: 'Wynik Powietrze czy Tlen',
				},
				value: {
					0: 'Powietrze',
					2: 'Tlen',
				},
				type: 'RADIO',
			},
		},
		{
			code: 'KREA',
			params: ['KREA'],
		},
	],
	examinationsCompactParamsCodesExcluded = ['Uwagi'],
	sortByDate = function (params, field) {
		/* examination_date */

		return params.sort((a, b) => {
			return new Date(a?.[field]) - new Date(b?.[field]) > 0 ? -1 : 1
		})
	},
	getParamShortname = function (paramName) {
		const config = [
			{
				shortName: 'HR',
				params: ['Puls'],
			},
			{
				shortName: 'RR',
				params: ['Liczba Oddechów'],
			},
		]

		let i = config.findIndex((e) =>
			e.params.includes(paramName) ? true : false
		)

		if (i >= 0) return config[i].shortName
		else return null
	},
	getParamsQsofaScale = function (params) {
		/* 
			Qsofa scale should be calculated on backend site
		*/

		const config = [
			{
				name: 'Liczba Oddechów',
				params: ['Liczba Oddechów'],
				requirements: {
					min: 22,
				},
			},
			{
				name: 'Skurczowe ciśnienie',
				params: ['Skurczowe Ciśnienie'],
				requirements: {
					max: 100,
				},
			},
			{
				name: 'Stan świadomości',
				params: ['Stan świadomości'],
				requirements: {
					values: ['C', 'V', 'P', 'U'],
				},
			},
		]

		let value = 0

		config.forEach((condition, i2) => {
			let i = params?.findLastIndex((p1) =>
				condition?.params?.includes(p1?.code)
			)
			if (i > -1) {
				const paramvalue = params?.[i]?.value,
					{ requirements } = condition

				if (requirements?.min && paramvalue >= requirements?.min)
					config[i2].pass = true
				if (requirements?.max && paramvalue <= requirements?.max)
					config[i2].pass = true
				if (
					requirements?.values &&
					requirements?.values?.includes(paramvalue)
				)
					config[i2].pass = true

				if (config[i2].pass) {
					config[i2].param = params?.[i]
					value += 1
				}
			}
		})

		return value
	},
	getParamsMerged = function (params) {
		const config = mergeCodes

		params = params.map((param) => {
			let i = config?.findIndex((e) =>
				e?.params.includes(param?.code) ? true : false
			)

			if (i > -1) {
				let overwrite = {
					code_original: param?.code,
					code: config[i].code,
				}

				if (
					config[i]?.transform &&
					config[i]?.transform?.code?.from == param?.code
				) {
					overwrite.value_original = param?.value

					if (config[i]?.transform?.code?.from) {
						/* only supported direction now */
						;(overwrite.value =
							config[i]?.transform?.value?.[param?.value]),
							(overwrite.type = config[i]?.transform?.type)
					}
				}

				return {
					...param,
					...overwrite,
				}
			}

			return param
		})

		return params
	},
	getParamsCustom = function (params) {
		const config = [
			{
				name: 'BP',
				value_style: 'concat',
				separator: '/',
				param_1: 'Skurczowe Ciśnienie',
				param_2: 'Rozkurczowe Ciśnienie',
				type: 'NUMBER',
			},
			{
				name: 'SpO2',
				param_1: 'SpO2',
				param_2: 'Powietrze czy Tlen',
				delete_param_1: true,
				delete_param_2: true,
				type: 'NUMBER',
				conditional_formatting: {
					Powietrze: null,
					Tlen: {
						'background-color-mod': '--Sp02-on-oxygen',
						'additional-icon-mod':
							'custom-o2-svg' /* 'fa-solid fa-mask-ventilator' */,
					},
				},
			},
		]
		
		const getCodes = function(code) {
			return mergeCodes[mergeCodes.findIndex(c => c.code === code)]?.params || []
		},
		isCodeExists = function(codes=[], params=[]) {
			return params?.findIndex((p) => codes.includes(p?.code)) > -1
		}

		config.forEach((customParam) => {
		
			const param1Codes = getCodes(customParam?.param_1),
				param2Codes = getCodes(customParam?.param_2),
				param1Exists = isCodeExists(param1Codes, params),
				param2Exists = isCodeExists(param2Codes, params)

			if (param1Exists && param2Exists) {
				let paramsGroup = params?.filter(
					(p1) =>
						p1?.code == customParam?.param_1 &&
						params?.findIndex(
							(p2) =>
								p2?.code == customParam?.param_2 &&
								p1?.examination_date == p2?.examination_date
						) > -1
				)

				if (paramsGroup?.length > 0) {
					paramsGroup = paramsGroup.map((p3) => {
						let p4 =
								params?.[
									params?.findIndex(
										(p5) =>
											p5?.code == customParam?.param_2 &&
											p3?.examination_date ==
												p5?.examination_date
									)
								],
							value = p3?.value /*  default value  */,
							conditional_formatting = null

						if (customParam?.value_style == 'concat')
							value =
								p3?.value + customParam?.separator + p4?.value

						if (customParam?.conditional_formatting)
							if (
								Object?.keys(
									customParam?.conditional_formatting
								).includes(p4?.value)
							)
								conditional_formatting =
									customParam?.conditional_formatting?.[
										p4?.value
									]

						return {
							name: customParam?.name,
							code: customParam?.name,
							type: customParam?.type,
							value: value,
							value_2: p3?.value,
							unit: p3?.unit,
							examination_date: p3?.examination_date,
							custom: 'value_2',
							conditional_formatting: conditional_formatting,
							param_1: {
								...p3,
							},
							param_2: {
								...p4,
							},
						}
					})

					if (customParam?.delete_param_1)
						params = params.filter(
							(p6) => p6?.code != customParam?.param_1
						)
					if (customParam?.delete_param_2)
						params = params.filter(
							(p6) => p6?.code != customParam?.param_2
						)

					params = [...params, ...paramsGroup]
				}
			}
		})

		params = params.sort((a, b) => {
			return new Date(a.examination_date) - new Date(b.examination_date) >
				0
				? -1
				: 1
		})

		return params
	},
	getParamsFromExamination = function (examination, paramsLifeOnly = false) {
		let params = null

		if (examination?.result)
			params = examination?.result?.tabs[0]?.attribute
				?.filter(
					(paramRaw) =>
						!!paramRaw?.value &&
						((paramsLifeOnly &&
							enabledCodes.includes(paramRaw?.code)) ||
							!paramsLifeOnly)
				)
				?.map((paramRaw) => {
					return {
						examination_date: examination?.examination_date,
						code: paramRaw?.code,
						name: paramRaw?.name,
						short_name: getParamShortname(paramRaw?.name),
						type: paramRaw?.type /*  !isNaN(paramRaw?.value) ? 'NUMBER' : 'TEXT', */,
						isNaN: paramRaw?.isNaN,
						unit: paramRaw?.unit,
						norm: paramRaw?.norm,
						value: paramRaw?.value,
					}
				})

		return params
	},
	getParamsFromExaminations = function (
		examinations,
		paramsLifeOnly = false
	) {
		console.time(`[getParamsFromExaminations] restoring...`)
		let hash = `${paramsLifeOnly ? 'p-l' : 'p-a'}-${examinations
				.map((e) => e.id)
				.join(';')}`,
			prevCalculatedParams = getCalculatedParams(hash)

		console.timeEnd(`[getParamsFromExaminations] restoring...`)
		if (prevCalculatedParams) {
			console.log(`Restoring from cache calculated params`)

			return prevCalculatedParams
		}

		console.time(`[getParamsFromExaminations] calcuating...`)

		let examinationsWithParams = examinations.filter(
				(examination) =>
					((paramsLifeOnly && examination?.gui_type == 'params') ||
						!paramsLifeOnly) &&
					(examination?.result || examination?.result_oracle)
			),
			paramsCgm = []

		examinationsWithParams = sortByDate(
			examinationsWithParams,
			'examination_date'
		)

		const uniquePropsforParam = ['examination_date', 'value', 'code']

		examinationsWithParams
			.filter((examination) => examination?.result)
			.forEach((examination) => {
				const paramsTmp = getParamsFromExamination(
					examination,
					paramsLifeOnly
				)

				paramsCgm.push(paramsTmp)
			})

		let params = [].concat.apply([], paramsCgm)

		/* merge */
		params = getParamsMerged(params)

		/* get unique */
		params = deleteDuplicatesByProp(params, uniquePropsforParam)

		/* custom params */
		params = getParamsCustom(params)

		setCalculatedParams(hash, params)

		console.timeEnd(`[getParamsFromExaminations] calcuating...`)

		return params
	},
	getCalculatedParams = function (hash) {
		const key = 'calculated-params',
			itemStr = localStorage.getItem(key)

		if (!itemStr) {
			return null
		}

		const items = JSON.parse(itemStr),
			hashes = Object.keys(items)

		if (hashes.includes(hash)) {
			if (new Date().getTime() > items[hash]?.expiry) {
				delete items[hash]

				localStorage.setItem(key, JSON.stringify(items))
			}

			return items[hash]?.value
		}

		return null
	},
	removeCalculatedParams = function () {
		const key = 'calculated-params'

		localStorage.removeItem(key)
	},
	setCalculatedParams = function (hash, params) {
		const key = 'calculated-params',
			itemStr = localStorage.getItem(key)

		let items = {}

		if (itemStr) {
			items = JSON.parse(itemStr)
		}

		const hashes = Object.keys(items ?? {})

		if (!hashes.includes(hash)) {
			const ttl = 14 * 24 * 60 * 60 * 1000,
				item = {
					value: params,
					expiry: new Date().getTime() + ttl,
					ttl: ttl,
				}
			items[hash] = item
			localStorage.setItem(key, JSON.stringify(items))
		}

		return null
	},
	deleteDuplicatesByProp = function (arr, props) {
		return arr.filter(function (item, pos) {
			return (
				arr.findIndex((item2) => {
					let exaclyTheSame = 0

					props.forEach((prop) => {
						if (item[prop] == item2[prop]) {
							exaclyTheSame++
						}
					})

					return exaclyTheSame == props?.length
				}) == pos
			)
		})
	},
	isDuplicateExistInArray = function (arr, props) {
		//TODO optimize this or create simpler alternative
		return deleteDuplicatesByProp(arr, props)?.length !== arr.length
	},
	getChartDatasets = function (arr = [], value_prop = 'value') {
		let datasetsAmount = 0,
			prop = 'examination_date',
			notUniqDates = [],
			labels = [],
			datasets = []

		arr.forEach((e) => {
			let i = arr.filter((e1) => e1?.[prop] == e?.[prop])?.length

			datasetsAmount = datasetsAmount > i ? datasetsAmount : i

			if (i > 1 && !notUniqDates.includes(e?.[prop]))
				notUniqDates.push(e?.[prop])
		})

		console.debug(
			`[getChartDatasets] Found ${datasetsAmount} datasets.${notUniqDates?.length > 0 ? ` Not uniq dates: ${notUniqDates.join( ', ' )}`: ''}` 
		)

		labels = deleteDuplicatesByProp(arr, ['examination_date'])
			.map((e) => getFormattedDate(e.examination_date))
			.slice()
			.reverse()

		let datasetsRaw = []

		for (let index = 0; index < datasetsAmount; index++) {
			if (!datasetsRaw?.[index]) {
				datasetsRaw?.push([...arr])
			}

			notUniqDates.forEach((date) => {
				let o = datasetsRaw[index].filter((a) => a?.[prop] == date),
					selectedItem = null

				// suport only for one duplicate for one date
				if (o.length == 2) {
					selectedItem =
						o?.[0]?.[value_prop] > o?.[1]?.[value_prop]
							? o?.[1]
							: o?.[0]

					if (index > 0) {
						let selectedItemToDelete =
							o?.[0].value > o?.[1].value ? o?.[0] : o?.[1]

						let indexToDelete = datasetsRaw[index].findIndex(
							(a1) =>
								a1?.[prop] == selectedItemToDelete?.[prop] &&
								a1?.[value_prop] ==
									selectedItemToDelete?.[value_prop]
						)

						if (indexToDelete > -1)
							datasetsRaw[index].splice(indexToDelete, 1)
					}
				}

				let selectedItemIndex = datasetsRaw[index].findIndex(
					(a1) =>
						a1?.[prop] == selectedItem?.[prop] &&
						a1?.[value_prop] == selectedItem?.[value_prop]
				)

				if (selectedItemIndex > -1) {
					if (index == 0) {
						datasetsRaw[index].splice(selectedItemIndex, 1)
					} else if (index > 0) {
						datasetsRaw[index] = new Array(
							datasetsRaw?.[index]?.length
						).fill(null)
						datasetsRaw[index][selectedItemIndex] = selectedItem
					}
				}
			})

			let values = datasetsRaw[index]
				.map((e) => e?.[value_prop] ?? null)
				.slice()
				.reverse()

			const dataset = {
				label: '',
				backgroundColor: colors.success,
				data: values,
				pointRadius: [],
				pointBorderWidth: [],
				pointBorderColor: [],
				pointBackgroundColor: [],
				borderWidth: 6,
				borderColor: colors.success,
				fill: false,
				pointHitRadius: 20,
			}

			datasets.push(dataset)
		}

		// if(datasets?.length == 2){
		// 	datasets[0].order = 1
		// 	datasets[1].order = 0
		// }

		// console.debug(datasetsRaw)

		return {
			labels,
			datasets,
		}
	},
	getGroupParam = function (rawCode) {
		const config = mergeCodes

		let i = config?.findIndex((e) =>
			e?.params.includes(rawCode) ? true : false
		)

		return i > -1 ? config?.[i] : null
	},
	getGroupParamCode = function (rawCode) {
		return getGroupParam(rawCode)?.code
	},
	getParamsForPatientAlert = function (
		arr = [],
		rawName = null,
		rawCode = null
	) {
		console.time(`[getParamsForPatientAlert]`)

		const groupParam = getGroupParam(rawCode)

		if (!groupParam) return []

		arr = arr?.filter(
			(param) =>
				param.code == groupParam?.code ||
				param.name == rawName ||
				param.code_original == rawCode
		)

		console.timeEnd(`[getParamsForPatientAlert]`)

		return arr
	}

export default getParamsFromExaminations
