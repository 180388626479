import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import routes from '@/router/routes'

import { is_hash_user_pass_hash_exists } from '@/functions/auth'

import { getOldestSyncInfo, pendingSyncTimePassed } from '@/functions/sync'

import DatabaseService from '@/services/Database.service.js'

import { is_auth_not_expired } from '@/functions/auth'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
})

const originalPush = router.push;
router.push = function( ...args ) {
	return originalPush.call( this, ...args )?.catch( error => {
		if ( error ) {
			console.warn( `consumed router exception: ${error}` );
		} else {
			console.warn( `consumed unknown router exception ${new Error().stack.replace( /^[\s\S]+?at/, "at" )}` );
		}
	} );
};

router.beforeEach(async (to, from, next) => {
	await store.dispatch('CurrentUser/updateNetworkStatus')

	if (to.matched.some((record) => record.meta.requiresAuth) && from.name !== 'login') {
		if (!store.state.CurrentUser.instance) {
			/* get last logged user from cache */
			try {
				await store.dispatch('CurrentUser/get_user_data_from_cache')
			} catch (error) {
				console.error(error)
			}
		}

		if (!store.state.CurrentUser.instance)
			await store.dispatch('CurrentUser/requireUserInfo')

		if (!store.state.CurrentUser.instance)
			await store.dispatch('CurrentUser/login_with_token')

		if (
			(!store.state.CurrentUser.instance ||
				!is_hash_user_pass_hash_exists()) &&
			to.name !== 'login' &&
			from.name !== 'login'
		)
			next({ name: 'login', query: { redirect: to.path } })
	}

	if (
		to.name !== 'login' &&
		to.name !== 'database-sync' &&
		from.name !== 'database-sync' &&
		store.state.CurrentUser.instance &&
		store.state.CurrentUser.network_status === 'online' &&
		is_auth_not_expired()
	) {
		const oldestSyncInfo = getOldestSyncInfo(),
			{ shoudSync, shouldReset } = oldestSyncInfo

		const isInitialized =
			store.state.CurrentDatabase.initialization?.isInitialized

		if (!isInitialized) {
			if (shoudSync || shouldReset) {
				const { diffHumanized } = oldestSyncInfo

				if (shouldReset) {
					console.debug(
						`Najstarsza synchronizacja odbyła się ${diffHumanized} temu, zostanie rozpoczete czyszczenie bazy oraz wymuszone przekierowanie do strony synchronizacji.`
					)
					;(await DatabaseService.singleton()).removeDatabase()

					next({
						name: 'database-sync',
						query: {
							redirect: to.path,
						},
					})
				} else if (shoudSync) {
					console.debug(
						`Najstarsza synchronizacja odbyła się ${diffHumanized} temu, zostanie rozpoczeta synchronizacja.`
					)
					;(await DatabaseService.singleton()).syncDatabase()
				}
			}

			if (pendingSyncTimePassed()) {
				console.debug(
					`Ostatnia synchronizacja odbyła się w czasie trwania importu, zostanie rozpoczeta synchronizacja.`
				)
				;(await DatabaseService.singleton()).syncDatabaseV2({forceIndexes: true})
			}
		}
	}

	if (to.matched.some((record) => record.meta.requireAuthenticatedClasses)) {
		await store.dispatch('CurrentUser/requireAuthenticatedClasses')
	}

	if (to.matched.some((record) => record.meta.requiresRoleZWR)) {
		if (
			['Personel_ZWR', 'Admin'].includes(
				store.state.CurrentUser.instance?.role
			)
		) {
			// next();
		} else {
			next({ name: 'login', query: { redirect: '/' } })
		}
	}

	if (to.matched.some((record) => record.meta.requirePublicClasses)) {
		await store.dispatch('CurrentUser/requirePublicClasses')
	}

	if (
		from.name == 'statistics' &&
		store.state.CurrentStays.dashboard_config.panel_prev_url &&
		to.name !== 'stay'
	) {
		const route =
			store.state.CurrentStays.dashboard_config.panel_prev_url.fullPath

		store.dispatch('CurrentStays/setDashboardConfig', {
			panel_prev_url: null,
		})

		try {
			next({ path: route })
		} catch (e) {
			//
		}
	}

	if (
		store.state.CurrentUser?.instance?.role === 'Personel_ZWR' &&
		to.name === 'zwr' &&
		['stay', 'start'].includes(from.name) &&
		store.state.CurrentUser?.network_status === 'online' &&
		is_auth_not_expired()
	) {
		const oldestSyncInfo = getOldestSyncInfo('zwr_monitor_orders'),
			{ shoudZwrDedicatedSync } = oldestSyncInfo

		if (shoudZwrDedicatedSync) {
			const isInitialized =
				store.state.CurrentDatabase.initialization?.isInitialized

			if (!isInitialized) {
				const { diffHumanized } = oldestSyncInfo

				console.debug(
					`Najstarsza synchronizacja dedykowana zwr odbyła się ${diffHumanized} temu, zostanie rozpoczeta synchronizacja.`
				)
				;(await DatabaseService.singleton()).syncPartialForZwr()
			}
		}
	}

	next()
})

router.afterEach((to /* from */) => {
	Vue.nextTick(() => {
		if (to.meta.title) document.title = to.meta.title
	})
})

export default router
