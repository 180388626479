const couchUrlPrefix = process.env.VUE_APP_API_COUCH
console.log('couchUrlPrefix: ' + couchUrlPrefix)

const common = {
	waitForLeadership: true,
	options: {
		live: false,
		retry: false,
		batches_limit: 999999,
		//timeout: 5 * 60 * 1000
		checkpoint: 'target',
	},
	direction: {
		// read only
		pull: true,
		push: false,
	},
	allowedRules: ['admin', 'doctor', 'personel_zwr'],
}

export const createRemote = function (tablename) {
		if (!couchUrlPrefix || couchUrlPrefix?.length == 0)
			throw new Error(
				`No couch url environment variable "VUE_APP_API_COUCH"`
			)
			
		let couchUrl = couchUrlPrefix + '/' + tablename
			
		if(couchUrl[0] === '/')
			couchUrl = `${window.location.origin}${couchUrl}`

		return couchUrl
	},
	kebabCaseToCamelCase = (string) =>
		string
			.replace(/(-[a-z])/g, (letters) => `${letters[1].toUpperCase()}`)
			.replace(/^[a-z]/g, (letters) => `${letters.toUpperCase()}`)

export const syncConfig = {
	stays: {
		modelName: 'stay',
		...common,
		options: {
			...common.options,
			batch_size: 300,
		},
	},
	stay_notes: {
		modelName: 'stay-note',
		...common,
		direction: {
			pull: true,
			push: true,
		},
		options: {
			...common.options,
			batch_size: 100,
		},
	},
	stay_note_files: {
		modelName: 'stay-note-file',
		...common,
		direction: {
			pull: true,
			push: true,
		},
		options: {
			...common.options,
			batch_size: 10,
		},
	},
	stay_note_roles: {
		modelName: 'stay-note-role',
		...common,
		direction: {
			pull: true,
			push: true,
		},
		options: {
			...common.options,
			batch_size: 1000,
		},
	},
	patients: {
		modelName: 'patient',
		...common,
		options: {
			...common.options,
			batch_size: 3000,
		},
	},
	patient_profile_images: {
		modelName: 'patient-profile-image',
		...common,
		direction: {
			pull: true,
			push: true,
		},
		options: {
			...common.options,
			batch_size: 20,
		},
	},
	patient_alerts: {
		modelName: 'patient-alert',
		...common,
		options: {
			...common.options,
			batch_size: 500,
		},
	},
	alert_attributes: {
		modelName: 'alert-attribute',
		...common,
		options: {
			...common.options,
			batch_size: 500,
		},
	},
	alert_attribute_codes: {
		modelName: 'alert-attribute-code',
		...common,
		options: {
			...common.options,
			batch_size: 500,
		},
	},
	alert_attribute_levels: {
		modelName: 'alert-attribute-level',
		...common,
		options: {
			...common.options,
			batch_size: 500,
		},
	},
	examinations: {
		modelName: 'examination',
		...common,
		options: {
			...common.options,
			batch_size: 2000,
		},
	},
	treatments: {
		modelName: 'treatment-form',
		...common,
		options: {
			...common.options,
			batch_size: 1000,
		},
	},
	procedures: {
		modelName: 'procedure',
		...common,
		options: {
			...common.options,
			batch_size: 3000,
		},
	},
	diagnosis: {
		modelName: 'stay-diagnosis',
		...common,
		options: {
			...common.options,
			batch_size: 3000,
		},
	},
	drug_applications: {
		modelName: 'drug-application',
		...common,
		options: {
			...common.options,
			batch_size: 3000,
		},
	},
	org_units: {
		modelName: 'org-unit',
		...common,
		options: {
			...common.options,
			batch_size: 3000,
		},
	},
	zwr_monitor_orders: {
		modelName: 'zwr-monitor-order',
		...common,
		direction: {
			pull: true,
			push: true,
		},
		options: {
			...common.options,
			batch_size: 3000,
		},
		allowedRules: ['admin', 'personel_zwr'],
	},
	zwr_patient_alerts: {
		modelName: 'zwr-patient-alert',
		...common,
		options: {
			...common.options,
			batch_size: 3000,
		},
		allowedRules: ['admin', 'personel_zwr'],
	},
	org_unit_persons: {
		modelName: 'org-unit-person',
		...common,
		options: {
			...common.options,
			batch_size: 3000,
		},
	},
	org_unit_room_stays: {
		modelName: 'org-unit-room-stay',
		...common,
		direction: {
			pull: true,
			push: true,
		},
		options: {
			...common.options,
			batch_size: 3000,
		},
	},
	org_unit_rooms: {
		modelName: 'org-unit-room',
		...common,
		options: {
			...common.options,
			batch_size: 3000,
		},
	},
}

export default syncConfig
