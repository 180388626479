import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true })
class CurrentLog extends VuexModule {
	events = []
	key = 'events'
	
	@Mutation
	restoreFromCache() {
		let data = localStorage.getItem(this.key)

		if (data) {
			data = JSON.parse(data)

			this.events = data || []
		}
	}

	@Mutation
	saveToCache() {
		localStorage.setItem(this.key, JSON.stringify(this.events))
	}

	@Mutation
	resetState() {
		this.events = []
	}
	
	@Mutation
	appendEventToState(event) {
		this.events = [...this.events, event]
	}
	
	@Action({ rawError: true })
	async save() {
		this.context.commit('saveToCache')
	}

	@Action({ rawError: true })
	async restore() {
		this.context.commit('restoreFromCache')
	}

	@Action({ rawError: true })
	async reset() {
		this.context.commit('resetState')
		this.context.commit('saveToCache')
	}
	
	@Action({ rawError: true })
	async appendEvent(event) {
		this.context.commit('appendEventToState', event)
		this.context.commit('saveToCache')
	}
}

export default CurrentLog
