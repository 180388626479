import { couch_uuid, id, index } from './fields'

export const schema = {
	title: 'drug application schema',
	description: 'describes a single drug application',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},

		// persistent id
		stay_id_ref: {
			...id,
		},

		application_date: {
			...index,
			type: 'string',
		},
		application_way: {
			type: 'string',
		},
		code: {
			type: 'string',
		},
		comments: {
			type: 'string',
		},
		ean: {
			type: 'string',
		},
		form: {
			type: 'string',
		},
		frequency_name: {
			type: 'string',
		},
		name: {
			type: 'string',
		},
		order_date: {
			...index,
			type: 'string',
		},
		// order_modification_date: {
		// 	type: 'string',
		// },
		// order_start_date: {
		// 	type: 'string',
		// },
		// order_stop_date: {
		// 	type: 'string',
		// },
		// parent_service_id: {
		// 	type: 'integer',
		// },
		// remote_id: {
		// 	type: 'integer',
		// },
		service_id: {
			...id,
		},
		status: {
			type: 'string',
		},
		unit_name: {
			type: 'string',
		},
		units_count: {
			type: 'string',
		},
	},
	required: ['couch_uuid', 'stay_id_ref'],
	indexes: [
		['stay_id_ref', 'order_date'],
	],
}

export default schema
