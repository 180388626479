import { couch_uuid, id } from './fields'

export const schema = {
	title: 'org units',
	description: 'describes a unit',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},
		name: {
			type: 'string',
		},
		code: {
			type: 'string',
		},
		is_active: {
			type: 'boolean',
		},
		is_assigned: {
			type: 'boolean',
		},
		last_import_date: {
			type: 'string',
		},
		phone: {
			type: ['string', 'null'],
		},
	},
	required: ['couch_uuid', 'name', 'code'],
}

export default schema
