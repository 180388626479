export const repoCommitHashKey = 'VUE_APP_COMMIT_HASH',
	reloadWindow = function () {
		// https://stackoverflow.com/questions/1011605/clear-the-cache-in-javascript
		console.debug(`[reloadWindow] reloading window...`)

		window.location.reload(true)
	},
	shouldClearCache = function () {
		const hash = process.env.VUE_APP_COMMIT_HASH,
			savedHash = localStorage.getItem(repoCommitHashKey)

		localStorage.setItem(repoCommitHashKey, hash)

		if (savedHash && savedHash !== hash) reloadWindow()
	}
