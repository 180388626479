import { couch_uuid, id, index } from './fields'

export const schema = {
	title: 'threatment schema',
	description: 'describes a single threatment',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},

		// persistent id
		stay_id_ref: {
			...id,
			...index,
		},

		author_name: {
			type: 'string',
		},
		creation_date: {
			...index,
			type: 'string',
		},
		modification_date: {
			type: ['string', 'null'],
		},
		name: {
			...index,
			type: 'string',
		},
		form_id_ref: { ...id },
		form: {
			type: 'string',
		},
		stay_orgunit_code: {
			type: 'string',
		},
		hospitalisation_id_ref: {
			...id,
		},
	},
	required: ['couch_uuid'],
	indexes: [
		['hospitalisation_id_ref', 'creation_date'],
	],
}

export default schema
