import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

import {
	keyLastSync,
	dateFormatCustom,
	calculateDuration,
} from '@/functions/sync'
import { getLengthOfQueries } from '@/services/database/indexes'

@Module({ namespaced: true })
class CurrentDatabase extends VuexModule {
	replications = {}
	status = {}
	config = {
		redirectAfterComplete: process.env
			.VUE_APP_SYNC_AUTO_REDIRECT_AFTER_COMPLETE
			? process.env.VUE_APP_SYNC_AUTO_REDIRECT_AFTER_COMPLETE === 'true'
			: null,
		autoStartSync: process.env.VUE_APP_SYNC_AUTO_START
			? process.env.VUE_APP_SYNC_AUTO_START === 'true'
			: null,
	}
	keyReplications = 'sync-replications'

	initialization = null

	indexes = null

	tests = {}

	@Mutation
	setReplicationChange(replication) {
		const { table, change } = replication,
			direction = change.direction || 'pull'

		if (change?.docs) change.docs = null
		if (change?.change?.docs) change.change.docs = null

		this.replications[table] = {
			...this.replications[table],
			change: {
				...this.replications?.[table]?.change,
				[direction]: change?.change ? change.change : change,
			},

			// old way
			[direction]: change.change,
		}
	}

	@Mutation
	setReplicationComplete(replication) {
		const { table, completed } = replication,
			direction = !(completed.push && completed.pull) ? 'pull' : null

		if (completed?.docs) completed.docs = null
		if (completed?.push?.docs) completed.push.docs = null
		if (completed?.pull?.docs) completed.pull.docs = null

		if (direction)
			this.replications[table] = {
				...this.replications[table],
				completed: {
					...this.replications[table]?.completed,
					[direction]: {
						...completed,
						duration: calculateDuration(completed),
					},
				},
				error: null,
				init: false,

				// old way
				[direction]: {
					...this.replications[table]?.[direction],
					...completed,
				},
			}
		else
			this.replications[table] = {
				...this.replications[table],
				completed: {
					...this.replications[table]?.completed,
					push: {
						...completed.push,
						duration: calculateDuration(completed.push),
					},
					pull: {
						...completed.pull,
						duration: calculateDuration(completed.pull),
					},
				},
				error: null,
				init: false,

				// old way
				...completed,
			}

		/* saving the date of the last successful synchronization */
		const keyName = keyLastSync,
			currentDates = JSON.parse(localStorage.getItem(keyName) || '{}')

		currentDates[table] =
			new Date().getTime() /* UTC timestamp in milliseconds */

		localStorage.setItem(keyName, JSON.stringify(currentDates))
	}

	@Mutation
	setReplicationActivity(replication) {
		const { table, active } = replication

		this.replications[table] = {
			...this.replications[table],
			active: active,
			error: active ? null : this.replications[table]?.error,
		}
	}

	@Mutation
	setReplicationInit(replication) {
		const { table, init = true } = replication

		this.replications[table] = {
			...this.replications[table],
			init: init,
		}

		if (init === true) {
			this.replications[table].completed = null
			this.replications[table].change = null
		}
	}

	@Mutation
	appendIndexesQueries(data) {
		if (data.queriesDone && data.collectionName) {
			let queriesDone = this.initialization.indexes.queriesDone

			if (!queriesDone) queriesDone = {}

			if (!queriesDone[data.collectionName])
				queriesDone[data.collectionName] = []

			const q = [...queriesDone[data.collectionName], ...data.queriesDone]

			this.initialization = {
				...this.initialization,

				indexes: {
					...this.initialization.indexes,
					queriesDone: {
						...queriesDone,
						[data.collectionName]: q,
					},
				},
			}
		}
	}

	@Mutation
	setInitialization(data) {
		if (this.initialization?.isInitialized && data?.init) {
			this.initialization = {
				...this.initialization,
				isInitialized: data?.init,
				collections: [
					...new Set([
						...(this.initialization?.collections || []),
						...data?.collections,
					]),
				],
				indexes: {
					...this.initialization?.indexes,
					...data?.indexes,
					queriesDone: {
						...this.initialization?.indexes?.queriesDone,
						...data?.indexes?.indexes,
					},
				},
				config: {
					...this.initialization?.config,
					...data?.config,
					code: [
						...new Set(
							typeof this.initialization?.config.code === 'string'
								? [
										this.initialization?.config.code,
										data?.config.code,
								  ]
								: [
										...this.initialization?.config.code,
										data?.config.code,
								  ]
						),
					],
					silent:
						this.initialization?.config.silent &&
						data?.config.silent,
					skipValidation:
						this.initialization?.config.skipValidation &&
						data?.config.skipValidation,
				},
			}
		} else {
			this.initialization = {
				...this.initialization,
				isInitialized: data?.init,
				collections: data?.collections,
				indexes: data.indexes,
				config: data?.config,
			}

			if (data?.init) {
				this.initialization = {
					...this.initialization,
					initializationStartedTime: new Date().getTime(),
				}
			}
		}
	}

	@Mutation
	removeInitialization() {
		if (this.initialization?.isInitialized)
			this.initialization.isInitialized = false
	}

	@Mutation
	setReplicationError(replication) {
		const { table, error } = replication

		this.replications[table] = {
			...this.replications[table],
			error: error,
			errorCount: parseInt(this.replications[table]?.errorCount) + 1 || 1,
			active: false,
		}
	}

	@Mutation
	setReplicationTest(payload) {
		const { collectionName, batchSize, result } = payload

		this.tests[collectionName] = {
			...this.tests[collectionName],
			[batchSize]: result,
		}
	}

	@Mutation
	restore() {
		let data = localStorage.getItem(this.keyReplications)

		if (data) {
			data = JSON.parse(data)

			this.replications = data
		}
	}

	@Mutation
	save() {
		localStorage.setItem(
			this.keyReplications,
			JSON.stringify(this.replications)
		)
	}

	@Mutation
	resetStatus() {
		this.status = {}
	}

	@Mutation
	resetIndexes() {
		this.indexes = {}
	}

	@Mutation
	reset(collection) {
		if (collection && this.replications?.[collection])
			this.replications[collection] = {}
		else if (!collection) this.replications = {}
	}

	@Mutation
	async updateStatus() {
		const { replications } = this,
			collections = this.initialization?.collections,
			indexes = this.initialization?.indexes

		if (replications) {
			let progressValue = 0,
				readDocs = 0,
				pendingDocs = 0,
				isActive = false,
				collectionsError = [],
				collectionsActive = [],
				collectionsCompleted = [],
				collectionsInitialized = []

			collections?.forEach((collection) => {
				let pending = 0,
					read = 0

				const change = replications?.[collection]?.change,
					completed = replications?.[collection]?.completed,
					error = replications?.[collection]?.error,
					active = replications?.[collection]?.active,
					init = replications?.[collection]?.init

				if (active) collectionsActive.push(collection)
				if (error) collectionsError.push(collection)
				if (completed) collectionsCompleted.push(collection)
				if (init) collectionsInitialized.push(collection)

				let push = null,
					pull = null

				if (completed) {
					push = completed?.push
					pull = completed?.pull
				} else if (change && !error) {
					push = change?.push
					pull = change?.pull
				}

				if (push) {
					pending += parseInt(push.pending)
					read += parseInt(push.docs_read)
				}

				if (pull) {
					pending += parseInt(pull.pending)
					read += parseInt(pull.docs_read)
				}

				const queriesPendingLength =
						indexes?.queriesPending?.[collection]?.length || 0,
					queriesDoneLength =
						indexes?.queriesDone?.[collection]?.length || 0

				let progressDocs = 0
				if (completed) {
					progressDocs = 100
				} else if (change && !error) {
					if (pending > 0 || read > 0) {
						progressDocs = (read / (read + pending)) * 100
					}
				}

				let progressWithIndexes = progressDocs

				if (
					queriesPendingLength > 0 &&
					queriesDoneLength !== queriesPendingLength
				) {
					progressWithIndexes *= Math.pow(
						0.75,
						queriesPendingLength - queriesDoneLength
					)
				}

				progressValue += progressWithIndexes / collections.length

				if (read > 0) readDocs += read
				if (pending > 0) pendingDocs += pending

				if (active || (init && !error)) isActive = true
			})

			const initializedCollectionsLength =
					this.initialization?.collections?.length,
				collectionsCompletedLength = collectionsCompleted?.length,
				indexQueriesLength = getLengthOfQueries(
					indexes?.queriesPending
				),
				indexQueriesDoneLength = getLengthOfQueries(
					indexes?.queriesDone
				),
				isInitialized = this.initialization?.isInitialized

			const isCompleted =
				isInitialized &&
				initializedCollectionsLength === collectionsCompletedLength &&
				indexQueriesLength === indexQueriesDoneLength

			const isFailed =
				isInitialized &&
				collectionsError.length > 0 &&
				collectionsActive.length === 0

			let finishedTime = this.status?.finishedTime ?? null

			if (this.status && !this.status.isCompleted && isCompleted) {
				finishedTime = new Date().getTime()

				// removeSyncInitialize()
				this.initialization.isInitialized = false // removeInitialization

				// dump timings
				let timings = Object.keys(this.replications || {})
					.map((co) => ({
						name: co,
						completed: this.replications?.[co]?.completed,
					}))
					.map((co) => ({
						name: co.name,
						push: co?.completed?.push?.duration,
						pull: co?.completed?.pull?.duration,
					}))

				console.table(timings)

				//dump docs_read
				let docs_read_dump = Object.keys(this.replications || {})
					.map((co) => ({
						name: co,
						completed: this.replications?.[co]?.completed,
					}))
					.map((co) => ({
						name: co.name,
						push: co?.completed?.push?.docs_read,
						pull: co?.completed?.pull?.docs_read,
					}))

				console.table(docs_read_dump)
			}

			if (
				this.status &&
				!this.status.isCompleted &&
				!isCompleted &&
				isFailed
			) {
				this.initialization.isInitialized = false
			}

			this.status = {
				...this.status,
				progressValue,
				readDocs,
				pendingDocs,
				isCompleted,
				isActive,
				isFailed,
				finishedTime,
				collectionsError,
				collectionsActive,
				collectionsCompleted,
				collectionsInitialized,
			}
		}
	}

	@Action({ rawError: true })
	async updateReplicationChangeInfo(data) {
		this.context.commit('setReplicationChange', data)
		this.context.commit('save', data)
		this.context.commit('updateStatus')
	}

	@Action({ rawError: true })
	async updateReplicationCompleteInfo(data) {
		this.context.commit('setReplicationComplete', data)
		this.context.commit('save', data)
		this.context.commit('updateStatus')
	}

	@Action({ rawError: true })
	async updateReplicationActivityInfo(data) {
		this.context.commit('setReplicationActivity', data)
		this.context.commit('save', data)
		this.context.commit('updateStatus')
	}

	@Action({ rawError: true })
	async updateReplicationTest(data) {
		this.context.commit('setReplicationTest', data)
	}

	@Action({ rawError: true })
	async updateReplicationError(data) {
		this.context.commit('setReplicationError', data)
		this.context.commit('save', data)
		this.context.commit('updateStatus')
	}

	@Action({ rawError: true })
	async updateReplicationInit(data) {
		this.context.commit('setReplicationInit', data)
		this.context.commit('save', data)
		this.context.commit('updateStatus')
	}

	@Action({ rawError: true })
	async updateInit(data) {
		this.context.commit('reset')

		this.context.commit('setInitialization', data)

		this.context.commit('updateStatus')
	}

	@Action({ rawError: true })
	async appendIndexes(data) {
		this.context.commit('appendIndexesQueries', data)

		this.context.commit('updateStatus')
	}
	@Action({ rawError: true })
	async saveReplicationInfo() {
		console.debug(
			`[saveReplicationInfo] CurrentDatabase replication state saving...`
		)
		this.context.commit('save')
		this.context.commit('updateStatus')
	}

	@Action({ rawError: true })
	async restoreReplicationInfo() {
		console.debug(
			`[restoreReplicationInfo] CurrentDatabase replication state restoring...`
		)
		this.context.commit('restore')
		this.context.commit('updateStatus')
	}

	@Action({ rawError: true })
	async resetReplicationInfo(collection) {
		console.debug(
			`[resetReplicationInfo] CurrentDatabase replication state reseting...`
		)
		this.context.commit('reset', collection)
		this.context.commit('resetStatus') //TODO check if is required
		this.context.commit('resetIndexes')
		this.context.commit('updateStatus')
		this.context.commit('save')
	}

	@Action({ rawError: true })
	async replicationUpdateStatus() {
		this.context.commit('updateStatus')
	}

	get isReplicationCompleted() {
		return this.status.isCompleted
	}

	get isReplicationActive() {
		return this.status.isActive
	}

	get isReplicationInitialized() {
		return this.initialization?.isInitialized
	}

	get getLastChangeOnRemote() {
		const collectionName = 'org_units'

		return dateFormatCustom(
			this.replications?.[collectionName]?.completed?.pull?.last_seq,
			'last_seq'
		)
	}
}

export default CurrentDatabase
