export const promiseAllSynchronous = function (
	arr = [],
	func = async () => {
		return
	}
) {
	arr.reduce(function (p, item) {
		return p.then(function () {
			return func(item)
		})
	}, Promise.resolve())
		.then(function () {
			// all done here
			return
		})
		.catch(function (err) {
			// error here
			console.error(`promiseAllSynchro`, err)
		})
}
