const stay_id_ref = 0,
	patient_uuid = 0,
	hospitalisation_id_ref = 0

export const queries = {
		stays: [
			{
				selector: {
					org_unit_code: { $eq: '' },
				},
				sort: [{ org_unit_code: 'asc', couch_uuid: 'asc' }],
			},
		],
		drug_applications: [
			{
				selector: {
					stay_id_ref,
				},
				sort: [{ stay_id_ref: 'desc' }, { order_date: 'desc' }],
			},
		],
		examinations: [
			{
				selector: {
					stay_id_ref,
				},
				sort: [{ stay_id_ref: 'desc' }, { examination_date: 'desc' }],
			},
			{
				selector: {
					hospitalisation_id_ref,
				},
				sort: [{ hospitalisation_id_ref: 'desc' }, { examination_date: 'desc' }],
			},
			{
				selector: {
					hospitalisation_id_ref,
				},
				sort: [{ hospitalisation_id_ref: 'desc' }, { order_date: 'desc' }],
			},
		],
		treatments: [
			{
				selector: {
					hospitalisation_id_ref,
				},
				sort: [{ hospitalisation_id_ref: 'desc' }, { creation_date: 'desc' }],
			},
		],
		zwr_monitor_orders: [
			{
				selector: {
					stay_id_ref,
				},
				sort: [],
			},
		],
		zwr_patient_alerts: [
			{
				selector: {
					patient_uuid,
				},
				sort: [{ patient_uuid: 'asc' }],
			},
		],
		patient_profile_images: [
			{
				selector: {
					patient_uuid,
				},
				sort: [],
			},
		],
		org_unit_persons: [
			{
				selector: {},
				sort: [{ org_unit_uuid: 'asc' }, { person_id_ref: 'asc' }],
			},
		],
	},
	getQueriesForCollection = function (collectionName = null) {
		return queries?.[collectionName] || []
	},
	getLengthOfQueries = function (obj = {}) {
		return Object.keys(obj).reduce((acc, key) => acc + obj[key].length, 0)
	}
