import { couch_uuid, id, index } from './fields'

export const schema = {
	title: 'examination schema',
	description: 'describes a single examination',
	version: 0,
	keyCompression: false,
	primaryKey: 'couch_uuid',
	type: 'object',
	properties: {
		couch_uuid,
		id: {
			...id,
		},

		// persistent id
		stay_id_ref: {
			...id,
		},

		title: {
			type: 'string',
		},
		examination_date: {
			...index,
			type: 'string',
		},
		gui_type: {
			type: ['string', 'null'],
		},
		order_date: {
			...index,
			type: 'string',
		},
		performing_unit_gui_type: {
			type: ['string', 'null'],
		},
		// performing_unit_name: {
		// 	type: 'string',
		// },
		// priority_name: {
		// 	type: 'string',
		// },
		result_form_id: {
			type: ['integer', 'null'],
		},
		result: {
			type: ['string', 'null'],
		},
		// result_oracle: {
		// 	type: ['string', 'null'],
		// },
		// order_form: {
		// 	type: 'string',
		// },
		service_id_ref: {
			...id,
		},
		service_name: {
			type: 'string',
		},
		status_name: {
			type: 'string',
		},
		// status_gui_icon: {
		// 	type: 'string',
		// },
		// status_gui_marker_color: {
		// 	type: 'string',
		// },
		result_form_contain_news: {
			type: 'boolean',
		},
		stay_orgunit_code: {
			type: 'string',
		},
		hospitalisation_id_ref: {
			...id,
		},
	},
	required: ['couch_uuid'],
	indexes: [
		['stay_id_ref', 'examination_date'],
		// ['stay_id_ref', 'order_date'],
		['hospitalisation_id_ref', 'examination_date'],
		['hospitalisation_id_ref', 'order_date'],
	],
}

export default schema
