const devStatusesMissingStyles = []

const config = [
	{
		statuses: [
			'Wyniki',
			'Podano',
			'Zmienione wyniki',
			'Wykonano',
			'Częściowe wyniki',
			'Wyniki do weryf.',
		],
		norm: [],
		data: {
			'bg-class': 'bg-green',
			'icon-class': 'fa-solid fa-square-check',
		},
	},
	{
		statuses: ['Anulowano zlecenie', 'Odstawiono', 'Anulowane zlecenie'],
		norm: ['upper'],
		data: {
			'bg-class': 'bg-red',
			'icon-class': 'fa-solid fa-square-xmark',
		},
	},
	{
		statuses: ['Zarejestrowane', 'Nowe zlecenie', 'Zlecono'],
		norm: ['lower'],
		data: {
			'bg-class': 'bg-blue',
			'icon-class': 'fa-solid fa-square-arrow-right',
		},
	},
	{
		statuses: [null, undefined],
		norm: [],
		data: {
			'bg-class': 'bg-gray',
			'icon-class': null,
		},
	},
	{
		statuses: [],
		norm: [],
		data: {
			'bg-class': null,
			'icon-class': null,
		},
	},
]

export const getStyleByStatus = function (status) {
		let i = config.findIndex((e) =>
			e['statuses']?.includes(status) ? true : false
		)

		if (i >= 0) return config[i].data
		else if (process.env.NODE_ENV === 'development')
			if (!devStatusesMissingStyles.includes(status)) {
				devStatusesMissingStyles.push(status)
				console.debug(
					'Brak zdefiniowanego stylu dla statusu: ' +
						JSON.stringify(devStatusesMissingStyles)
				)
			}

		return config[config.length - 1].data
	},
	getStyleByNorm = function (norm) {
		let i = config.findIndex((e) =>
			e['norm']?.includes(norm) ? true : false
		)

		if (i >= 0) return config[i].data

		return config[config.length - 1].data
	},
	statusesResults = config[0].statuses,
	statusesCancelled = config[1].statuses

export default getStyleByStatus
