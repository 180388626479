export const titleDefault = 'HosPanel'

export const routes = [
	{
		path: '/',
		name: 'start',
		component: () =>
			import(/* webpackChunkName: "start" */ '@/views/panel/Start.vue'),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () =>
			import(/* webpackChunkName: "start" */ '@/views/Login.vue'),
		meta: {
			layout: 'AppLayoutLogin',
			requiresAuth: false,
			requirePublicClasses: true,
			title: `${titleDefault} - Logowanie`,
		},
	},
	{
		path: '/recover-password',
		name: 'recover-password',
		component: () =>
			import(
				/* webpackChunkName: "start" */ '@/views/RecoverPassword.vue'
			),
		meta: {
			layout: 'AppLayoutLogin',
			requiresAuth: false,
			title: `${titleDefault} - Odzyskiwanie hasła`,
		},
	},
	{
		path: '/stays',
		name: 'stays',
		component: () =>
			import(/* webpackChunkName: "stay" */ '@/views/panel/Stays.vue'),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
			title: `${titleDefault} - Pobyty`,
		},
	},
	{
		path: '/stay/:id',
		name: 'stay',
		component: () =>
			import(
				/* webpackChunkName: "stay" */ '@/views/panel/stay/index.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Pobyt`,
		},
	},
	{
		path: '/stay/:id/examinations',
		name: 'examinations',
		redirect: (to) => {
			return { ...to, name: 'examinations.examinations-timeline' }
		},
		component: () =>
			import(
				/* webpackChunkName: "stay" */ '@/views/panel/stay/examinations/index.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Zlecone badania`,
		},
		children: [
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Zlecone badania - lista`,
				},
				name: 'examinations.examinations-timeline',
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "stay" */ '@/views/panel/stay/examinations/ExaminationsTimeline.vue'
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Zlecone badania - kompakt`,
				},
				name: 'examinations.examinations-compact',
				path: 'compact',
				component: () =>
					import(
						/* webpackChunkName: "stay" */ '@/views/panel/stay/examinations/ExaminationsCompact.vue'
					),
			},
		],
	},
	{
		path: '/stay/:id/stats/:param',
		name: 'statistics',
		component: () =>
			import(
				/* webpackChunkName: "stay" */ '@/views/panel/stay/Parameter.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Paramatry - statystyki`,
		},
	},
	{
		path: '/stay/:id/params',
		name: 'params',
		redirect: (to) => {
			return { ...to, name: 'params.params-latest' }
		},
		component: () =>
			import(
				/* webpackChunkName: "stay" */ '@/views/panel/stay/Parameters.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Parametry`,
		},
		children: [
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Parametry - Ostatnie`,
				},
				name: 'params.params-latest',
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "stay" */ '@/components/stay/params/ParamsLatest.vue'
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Parametry - Lista`,
				},
				name: 'params.params-compact',
				path: 'compact',
				component: () =>
					import(
						/* webpackChunkName: "stay" */ '@/components/stay/params/ParamsCompact.vue'
					),
			},
		],
	},
	{
		path: '/stay/:id/drug-applications',
		name: 'drug-applications',
		redirect: (to) => {
			return { ...to, name: 'drug-applications.drug-applications-compact' }
		},
		component: () =>
			import(
				/* webpackChunkName: "stay" */ '@/views/panel/stay/drug_applications'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Leki`,
		},
		children: [
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Leki - lista`,
				},
				name: 'drug-applications.drug-applications',
				path: 'timeline',
				component: () =>
					import(
						/* webpackChunkName: "stay" */ '@/views/panel/stay/drug_applications/DrugApplicationsTimeline'
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Leki - kompakt`,
				},
				name: 'drug-applications.drug-applications-compact',
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "stay" */ '@/views/panel/stay/drug_applications/DrugApplicationsCompact'
					),
			},
		],
	},
	{
		path: '/stay/:id/notes',
		name: 'notes',
		component: () =>
			import(
				/* webpackChunkName: "stay" */ '@/views/panel/stay/Notes.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Notatki`,
		},
	},
	{
		path: '/stay/:id/treatments',
		name: 'treatments',
		component: () =>
			import(
				/* webpackChunkName: "stay" */ '@/views/panel/stay/Treatments.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Dane opisowe`,
		},
	},
	// {
	// 	path: '/stay/:id/news2',
	// 	name: 'statistics-news2',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "stay" */ '@/views/panel/stay/News2.vue'
	// 		),
	// 	meta: {
	// 		layout: 'AppLayoutPatient',
	// 		requiresAuth: true,
	// 	},
	// },
	{
		path: '/zwr',
		name: 'zwr',
		component: () =>
			import(/* webpackChunkName: "zwr" */ '@/views/panel/Zwr.vue'),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
			// requirePublicClasses: true,
			// requireAuthenticatedClasses: true,
			requiresRoleZWR: true,
			title: `${titleDefault} - ZWR`,
		},
	},
	{
		path: '/database',
		name: 'database',
		redirect: (to) => {
			return { ...to, name: 'database.sync-adv' }
		},
		component: () =>
			import(/* webpackChunkName: "database" */ '@/views/Database.vue'),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
			title: `${titleDefault} - Database`,
		},
		children: [
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Database - Sync`,
				},
				name: 'database.sync-adv',
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "database" */ '@/views/database/Sync'
					),
			},
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Database - Preview`,
				},
				name: 'database.preview',
				path: 'preview',
				component: () =>
					import(
						/* webpackChunkName: "database" */ '@/views/database/Preview'
					),
			},
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Database - Preview`,
				},
				name: 'database.token',
				path: 'token',
				component: () =>
					import(
						/* webpackChunkName: "database" */ '@/views/database/Token'
					),
			},
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Database - Preview`,
				},
				name: 'database.token',
				path: 'token',
				component: () =>
					import(
						/* webpackChunkName: "database" */ '@/views/database/Token'
					),
			},
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Database - Preview`,
				},
				name: 'database.batch_size-test',
				path: 'batch-size-test',
				component: () =>
					import(
						/* webpackChunkName: "database" */ '@/views/database/BatchSizeTest'
					),
			},	
		]
	},
	{
		path: '/sync',
		name: 'database-sync',
		component: () =>
			import(
				/* webpackChunkName: "database" */ '@/views/DatabaseSyncSimple.vue'
			),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
			requiresRoleZWR: false,
			title: `${titleDefault} - Synchronizacja`,
		},
	},
	{
		path: '/stay/:id/rounds',
		redirect: (to) => {
			return { ...to, name: 'notes' }
		},
	},
	{
		path: '/stay/:id/descriptive-data',
		redirect: (to) => {
			return { ...to, name: 'treatments' }
		},
	},
	{
		path: '/stay/:id/anxieties',
		redirect: (to) => {
			return { ...to, name: 'drug-applications.drug-applications-compact' }
		},
	},
	{
		path: '/stay/:id/anxieties/timeline',
		redirect: (to) => {
			return { ...to, name: 'drug-applications.drug-applications' }
		},
	},
	{
		path: '/stay/:id/tests',
		redirect: (to) => {
			return { ...to, name: 'examinations.examinations-timeline' }
		},
	},
	{
		path: '/stay/:id/tests/compact',
		redirect: (to) => {
			return { ...to, name: 'examinations.examinations-compact' }
		},
	},
	
	
]

export default routes
